<template>
  <div class="zoom-control" v-if="leafletMap" style="padding-bottom: 10px">
    <div class="zoom-button">
      <v-btn
        small
        fab
        height="35"
        dark
        color="rgba(19, 55, 102, 0.72)"
        @click="zoomIn"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-btn small fab height="22">
      {{ zoom }}
    </v-btn>
    <div class="zoom-button">
      <v-btn
        small
        fab
        height="35"
        dark
        color="rgba(19, 55, 102, 0.72)"
        @click="zoomOut"
      >
        <v-icon small>mdi-minus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZoomControl",
  computed: {
    leafletMap() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return this.$store.getters.leafletMap.getZoom();
    }
  },
  methods: {
    zoomIn() {
      this.leafletMap.setZoom(this.leafletMap.getZoom() + 1);
    },
    zoomOut() {
      this.leafletMap.setZoom(this.leafletMap.getZoom() - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.zoom-control {
  pointer-events: painted;
  width: 36px;
  margin: 0 0 0 auto;
  .v-btn {
    border-radius: 0;
  }
  .zoom-button {
    width: 100%;
  }
  .zoom {
    text-align: center;
    background-color: white;
    font-weight: bold;
  }
  .v-btn--fab.v-size--small {
    width: 100%;
    padding: auto;
  }
}
</style>
