<template>
  <div class="main">
    <!-- Loading -->
    <div class="loading-mask" v-if="isLoading">
      <div class="loading-message">ページを読み込んでいます...</div>
      <VueLoading type="spin" color="#eee" :size="loadingSize" />
    </div>

    <!-- Title -->
    <v-card class="d-flex align-center" height="34" color="primary" tile>
      <v-img
        class="mx-2"
        contain
        width="20"
        height="20"
        max-height="20"
        max-width="20"
        :src="require('@/assets/images/png/gunma_logo.png')"
      ></v-img>
      <h5 class="white--text text-no-wrap mr-2 pt-1">{{ appTitle }}</h5>
      <v-btn icon @click="displayMenu" class="ml-auto">
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </v-card>

    <!-- Menu -->
    <MapMenu v-if="isShowMenu" @close="isShowMenu = false" />

    <ObservatoryMonitor v-if="isShowObservatoryMonitor" />

    <!-- Details -->
    <v-dialog
      v-model="isShowMapDetail"
      width="95wh"
      max-width="440"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar dense flat color="primary">
          <v-toolbar-title style="color: white">{{
            mapDetail.title
          }}</v-toolbar-title>
          <v-spacer />
          <v-btn dark icon @click="closeMapDetail">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <template v-if="mapDetail.contents === 'stage-detail'">
            <StageDetail />
          </template>
          <template v-if="mapDetail.contents === 'rainfall-detail'">
            <RainDetail />
          </template>
          <template v-if="mapDetail.contents === 'dam-detail'">
            <DamDetail />
          </template>
          <template v-if="mapDetail.contents === 'camera-detail'">
            <CameraDetail />
          </template>
          <template v-if="mapDetail.contents === 'live-camera-detail'">
            <LiveCameraDetail />
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Map -->
    <div :class="selectedRisk.slider ? 'ninety-height' : 'full-height'">
      <BaseMap @showMenu="displayMenu()" ref="mobileMap" />
      <BasetimeControl class="basetime-control" />
    </div>
    <!-- Slider -->
    <div v-show="selectedRisk.slider" class="ten-height">
      <TimeSeriesSlider />
    </div>
  </div>
</template>

<script>
import { VueLoading } from "vue-loading-template";
import BaseMap from "../components/BaseMap";
import StageDetail from "../components/base-map/details/StageDetail";
import RainDetail from "../components/base-map/details/RainDetail";
import DamDetail from "../components/base-map/details/DamDetail";
import CameraDetail from "../components/base-map/details/CameraDetail";
import LiveCameraDetail from "../components/base-map/details/LiveCameraDetail";
import TimeSeriesSlider from "../components/TimeSeriesSlider";
import MapMenu from "../components/base-map/MapMenu";
import BasetimeControl from "../components/base-map/BasetimeControl";
import { Features } from "../enums/Feature";
import { Risk } from "../enums/Risk";
import { BaseTile } from "../enums/BaseTile";
import ObservatoryMonitor from "../components/ObservatoryMonitor";
import { isPrivate } from "../utils/common";

export default {
  name: "Mobile",
  components: {
    TimeSeriesSlider,
    BaseMap,
    StageDetail,
    RainDetail,
    DamDetail,
    CameraDetail,
    LiveCameraDetail,
    VueLoading,
    MapMenu,
    BasetimeControl,
    ObservatoryMonitor
  },
  data() {
    return {
      isShowMenu: false,
      loadingSize: { width: "80px", height: "80px" },
      isPrivate
    };
  },
  created() {
    this.$store.dispatch("FETCH_OFFICES");
    this.$store.dispatch("FETCH_CITIES");
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateWindowHeight();
    });
    window.addEventListener("resize", this.calculateWindowHeight);

    this.loadQueryParams();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateWindowHeight);
  },
  computed: {
    isShowMapDetail() {
      return this.$store.getters.isShowMapDetail;
    },
    mapDetail() {
      return this.$store.getters.mapDetail;
    },
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    appTitle() {
      let title = process.env.VUE_APP_TITLE;
      if (title.includes("|")) {
        title = title.split("|")[0].trim();
      }
      return title;
    },
    isShowObservatoryMonitor() {
      return this.$store.getters.isShowObservatoryMonitor;
    }
  },
  methods: {
    closeMapDetail() {
      const dialog = {
        isShowing: false,
        contents: "",
        title: ""
      };
      this.$store.commit("SET_MAP_DETAIL", dialog);
    },
    displayMenu() {
      this.isShowMenu = true;
    },
    calculateWindowHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    loadQueryParams() {
      const map = this.$store.getters.leafletMap;
      // tile
      if (this.$route.query.tile) {
        const finded = Object.values(BaseTile).find(
          r => r.key === this.$route.query.tile
        );
        if (finded !== undefined) {
          this.$store.commit("SET_SELECTED_BASE_TILE", finded);
        }
      }
      // risk
      if (this.$route.query.risk) {
        if (this.$route.query.risk === "none") {
          this.$store.commit("SET_SELECTED_RISK", {});
        } else {
          const finded = Object.values(Risk).find(
            r => r.key === this.$route.query.risk
          );
          if (finded !== undefined) {
            this.$store.commit("SET_SELECTED_RISK", finded);
          }
        }
      }
      // hazard maps
      if (this.$route.query.hazardmaps) {
        let hazardmaps = this.$route.query.hazardmaps.split(",");
        hazardmaps = [...new Set(hazardmaps)];
        hazardmaps = hazardmaps.filter(key => Features[key] !== undefined);
        const selected = hazardmaps.map(key => {
          const feature = Features[key];
          return { ...feature };
        });
        if (selected.length > 0) {
          this.$store.commit("SET_SELECTED_HAZARD_MAPS", selected);
        }
      }
      // observatory
      if (
        this.$route.query.risk === Risk.OBSERVATORY.key &&
        this.$route.query.observatory &&
        this.$route.query.observatory !== ""
      ) {
        const arr = this.$route.query.observatory.split(",");
        if (arr.length > 0) {
          this.$store.commit("SET_RISK_SUB_CONTROL", {
            observatory: arr.map(a => Number(a))
          });
        }
      }
      // bulk hazard
      if (
        this.$route.query.bulkHazard &&
        this.$route.query.bulkHazard === "1"
      ) {
        this.$store.commit("SET_BULK_HAZARD", true);
      }
      // hazard legends
      if (
        this.$route.query.hazardLegends &&
        this.$route.query.hazardLegends === "1"
      ) {
        this.$store.commit("SET_IS_SHOW_HAZARD_MAP_CONTROL", true);
      }
      // usage panel
      if (
        this.$route.query.usagePanel &&
        this.$route.query.usagePanel === "1"
      ) {
        this.$store.commit("SET_IS_SHOW_USAGE_GUIDE_PANEL", true);
      }
      // shelter
      if (this.$route.query.shelter && this.$route.query.shelter === "1") {
        this.$store.commit("SET_VISIBLE_SHELTER", true);
      }
      if (this.$route.query.shelterex && this.$route.query.shelterex === "1") {
        this.$store.commit("SET_VISIBLE_SHELTER_EX", true);
      }
      // zoom
      if (this.$route.query.zoom) {
        const zoom = parseInt(this.$route.query.zoom, 10);
        if (isNaN(zoom) === false) {
          map.setZoom(zoom);
        }
      }
      // gps
      if (this.$route.query.gps && this.$route.query.gps === "1") {
        this.$refs.mobileMap.getCurrentPosition();
      }
      // lat lng
      else if (this.$route.query.lat && this.$route.query.lng) {
        const lat = parseFloat(this.$route.query.lat);
        const lng = parseFloat(this.$route.query.lng);
        if (isNaN(lat) === false && isNaN(lng) === false) {
          setTimeout(() => {
            map.panTo([lat, lng]);
          }, 1000);
        }
      } else {
        const item = localStorage.getItem("map-position");
        if (item !== null) {
          const data = JSON.parse(item);
          const map = this.$store.getters.leafletMap;
          if (!this.$route.query.zoom) {
            map.setZoom(data.zoom);
          }
          setTimeout(() => {
            map.panTo([data.lat, data.lng]);
          }, 1000);
        }
      }
      // mytimeline
      if (
        this.$route.query.mytimeline &&
        this.$route.query.mytimeline === "1"
      ) {
        this.$store.commit("SET_IS_MYTIMELINE", true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
}
.full-height {
  width: 100%;
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - 34px) !important;
}
.ninety-height {
  width: 100%;
  position: relative;
  height: calc(var(--vh, 1vh) * 100 - 34px - 50px) !important;
}
.ten-height {
  height: 50px !important;
}
.loading-mask {
  z-index: 6000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(88, 88, 88, 0.5);
  text-align: center;
}
.loading-message {
  margin-top: 40%;
  font-size: 0.9em;
  color: rgb(30, 30, 30);
}
</style>

<style lang="scss" scoped>
.basetime-control {
  top: 55px;
  right: 10px;
  position: absolute;
  pointer-events: none;
  z-index: 1500;
}
</style>
