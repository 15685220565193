<template>
  <div style="z-index: 20000 !important;" v-if="selectedDateRiskData">
    <l-rectangle
      v-if="rectangle.bounds != null"
      :bounds="rectangle.bounds"
      :l-style="rectangle.style"
    />
    <template v-for="(slicedImage, index) in slicedImages">
      <l-marker
        :key="index"
        :lat-lng="[slicedImage.markerLat, slicedImage.markerLng]"
        v-if="slicedImage.isInDeviceViewPort"
      >
        <l-icon :icon-anchor="[0, 0]">
          <div
            :style="{
              width: slicedImage.divWidth + 'px',
              height: slicedImage.divHeight + 'px',
              overflow: 'hidden',
              'pointer-events': 'none'
            }"
          >
            <img
              class="riskImage"
              alt="rainfallImage"
              :src="selectedDateRiskData.image.src"
              :style="{
                transform: `translate(0,${slicedImage.translateY}px)`,
                width: slicedImage.imgWidth + 'px',
                height: slicedImage.imgHeight + 'px',
                'pointer-events': 'none',
                opacity: opacity
              }"
            />
          </div>
        </l-icon>
      </l-marker>
    </template>
    <v-dialog v-model="dialog" width="1000">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon small @click="dialog = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <iframe width="990" height="660" :src="snakeLineUrl" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { LMarker, LIcon, LRectangle } from "vue2-leaflet";
import { Risk } from "../../../enums/Risk";
import moment from "moment";

export default {
  name: "dosya-layer",
  components: {
    LMarker,
    LIcon,
    LRectangle
  },
  data() {
    return {
      Risk,
      bounds: [],
      viewportBottomLat: 0,
      viewportTopLat: 0,
      dialog: false,
      snakeLineUrl: "",
      rectangle: {
        bounds: null,
        style: { color: "black", weight: 1 }
      },
      snakeLineLock: false
    };
  },
  computed: {
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    map() {
      return this.$store.getters.leafletMap;
    },
    dosyaInfo() {
      return this.$store.getters.dosyaInfo;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    opacity() {
      return this.$store.getters.riskSubControl.dosya;
    },
    selectedDateRiskData() {
      if (!this.selectedDate) {
        return;
      }
      if (!this.dosyaInfo || !this.dosyaInfo.imageInfos) {
        return;
      }
      return this.dosyaInfo.imageInfos.find(info =>
        this.selectedDate.isSame(moment.utc(info.date, "YYYY/MM/DD HH:mm"))
      );
    },
    slicedImages() {
      if (!this.map) {
        return [];
      }
      if (!this.dosyaInfo || !this.dosyaInfo.imageInfos) {
        return;
      }
      const imagesInfo = [];
      const bounds = this.dosyaInfo.bounds;
      const coordinate = {
        north: bounds[0][0],
        west: bounds[0][1],
        south: bounds[1][0],
        east: bounds[1][1]
      };
      const sliceCount = 50;
      const slicedLatHeight =
        (coordinate.north - coordinate.south) / sliceCount;
      for (let i = 0; i < sliceCount; i++) {
        const slicedImageBottomLeftPoint = this.map.project([
          coordinate.north - i * slicedLatHeight - slicedLatHeight,
          coordinate.west
        ]);
        const slicedImageTopRightPoint = this.map.project([
          coordinate.north - i * slicedLatHeight,
          coordinate.east
        ]);
        let slicedImageHeight =
          slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
        let slicedImageWidth =
          slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;
        if (
          Math.round(slicedImageBottomLeftPoint.y) <
          Math.round(
            slicedImageBottomLeftPoint.y + Math.ceil(slicedImageHeight)
          )
        ) {
          slicedImageHeight -= -1;
        }
        const markerLat = coordinate.north - i * slicedLatHeight;
        const markerLng = coordinate.west;
        const divHeight = Math.ceil(slicedImageHeight) - 1.4;
        imagesInfo.push({
          markerLat: markerLat,
          markerLng: markerLng,
          divWidth: slicedImageWidth,
          divHeight: divHeight,
          imgWidth: slicedImageWidth,
          imgHeight: slicedImageHeight * sliceCount,
          translateY: -(i * slicedImageHeight + 1),
          isInDeviceViewPort: this.isElementInDeviceViewPort(
            markerLat,
            markerLng,
            divHeight
          )
        });
      }
      return imagesInfo;
    }
  },
  watch: {
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.map.on("moveend", this.updateViewportLatitude);
    this.updateViewportLatitude();

    this.$store.commit("SET_USAGE_GUIDE", {
      title: "土砂災害警戒判定値",
      colors: [
        { color: "#10000e", value: "災害切迫【５】" },
        { color: "#a5009c", value: "危険　　【４】" },
        { color: "#ff251b", value: "警戒　　【３】" },
        { color: "#f1e539", value: "注意　　【２】" },
        { color: "#ffffff", value: "今後の情報等に留意" }
      ]
    });
  },
  beforeDestroy() {
    this.map.off("moveend", this.updateViewportLatitude);
  },
  methods: {
    loadData() {
      this.$store.dispatch("FETCH_DOSYA");
    },
    /**
     * 現在表示中画面（viewport）の最新緯度を取得する
     * @return {void}
     */
    updateViewportLatitude() {
      this.viewportBottomLat = this.getScreenBottomLat();
      this.viewportTopLat = this.getScreenTopLat();
    },
    /**
     * 現在表示中画面（viewport）の下の緯度取得
     * @return {latitude}
     */
    getScreenBottomLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMinY = [pointC.x, pointC.y - window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMinY);
      return latlng.lat;
    },
    /**
     * 現在表示中画面（viewport）の上のの緯度取得
     * @return {latitude}
     */
    getScreenTopLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMaxY = [pointC.x, pointC.y + window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMaxY);
      return latlng.lat;
    },
    /**
     * DOMエレメントが現在デバイス画面（viewport）内で表示されているか確認する
     * @param {latitude} elementBottomLat DOMエレメントの緯度
     * @param {longitude} elementBottomLng DOMエレメントの軽度
     * @param {float} elementHeightPx DOMエレメントの高さ（px）
     * @return {bool} true: デバイス画面内に表示されている、false: 画面外表示されている
     */
    isElementInDeviceViewPort(
      elementBottomLat,
      elementBottomLng,
      elementHeightPx
    ) {
      const elementTopLat = this.getElemenTopLat(
        [elementBottomLat, elementBottomLng],
        elementHeightPx
      );
      if (
        elementTopLat < this.viewportBottomLat &&
        elementTopLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat < this.viewportBottomLat &&
        elementBottomLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat >= this.viewportBottomLat &&
        elementTopLat <= this.viewportTopLat
      ) {
        return true;
      }
      return false;
    },
    /**
     * エレメントの上のの緯度取得
     * @param {Array<float>} elementTopLatLng
     * @param {float} divHeight
     * @return {latitude}
     */
    getElemenTopLat(elementTopLatLng, elementHeightPx) {
      let pointDivTop = this.map.latLngToContainerPoint(elementTopLatLng);
      let pointY = [pointDivTop.x, pointDivTop.y + elementHeightPx];
      let latlng = this.map.containerPointToLatLng(pointY);
      return latlng.lat;
    }
  }
};
</script>

<style scoped></style>
