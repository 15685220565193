<template>
  <div class="camera-container pa-3">
    <div class="mb-3">
      <h4>{{ selectedObservatory.name }}</h4>
      <div class="caption text--secondary">
        {{ selectedObservatory.address }}
      </div>
      <div class="caption text--secondary">
        {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
      </div>
    </div>
    <CameraChart />
  </div>
</template>

<script>
import moment from "moment";
import CameraChart from "./CameraChart";
export default {
  name: "CameraDetail",
  components: {
    CameraChart
  },
  data() {
    return {
      moment,
      currentImageIndex: 0
    };
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    }
  },
  methods: {
    updateCurrentImage(index) {
      this.currentImageIndex = index;
    }
  },
  mounted() {
    this.currentImageIndex = this.selectedObservatory.imageInfo.length - 1;
  }
};
</script>

<style lang="scss">
.current-image-area {
  max-height: 50vh;
  background-color: black;
  .current-image {
    width: 85%;
    height: 100%;
  }
}

.image-list {
  display: flex;
  flex-direction: row;
  .image-list-item {
    min-width: 10%;
    max-width: 30%;
    border-top: 1px solid rgb(66, 66, 66);
    border-bottom: 1px solid rgb(66, 66, 66);
    border-right: 1px solid rgb(66, 66, 66);
    cursor: pointer;
    background-color: white;
    display: inline-block;
    .times {
      text-align: center;
      padding: 2px 0;
      line-height: 1;
      width: 100%;
      font-size: 12px;
      font-weight: normal;
      color: rgb(65, 65, 65);
      background-color: white;
    }
  }
  .image-list-item:first-child {
    border-left: 1px solid rgb(66, 66, 66);
  }
  .current-image {
    .times {
      color: white;
      background-color: #0d47a1;
    }
  }
}
</style>
