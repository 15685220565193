<script>
import { Line } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
Chart.plugins.register(ChartAnnotationsPlugin);
import _ from "lodash";

export default {
  extends: Line,
  name: "StageRainChart",
  props: {
    timeSeries: [Object, Array],
    baseDate: Object,
    minDate: Object,
    maxDate: Object
  },
  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "降雨強度",
            yAxisID: "rain",
            xAxisID: "data",
            unit: "mm/h",
            pointBackgroundColor: "white",
            borderWidth: 3,
            backgroundColor: "#1C344C",
            tooltipLabelColor: "#1C344C",
            borderColor: "#1C344C",
            pointRadius: 0,
            lineTension: 0,
            pointHitRadius: 0,
            fill: true,
            data: [],
            spanGaps: true
          }
        ]
      },
      options: {
        layout: {
          padding: {
            right: 0,
            left: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              return (
                label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " " +
                unit
              );
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "rain",
              gridLines: {
                display: true
              },
              ticks: {
                reverse: true,
                max: 5,
                min: 0,
                stepSize: 1,
                beginAtZero: true,
                display: true,
                autoSkip: true,
                callback: tick => {
                  return ("        " + parseFloat(tick).toFixed(1)).slice(-6);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              type: "time",
              gridLines: {
                display: false
              },
              time: {
                tooltipFormat: "MM/DD HH:mm",
                displayFormats: {
                  minute: "HH:mm",
                  hour: "HH:mm",
                  day: "HH:mm",
                  week: "HH:mm",
                  month: "HH:mm"
                }
              },
              ticks: {
                min: this.minDate.toDate(),
                max: this.maxDate.toDate(),
                display: false,
                autoSkip: true
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "06:10",
              borderColor: "#FF0000",
              borderWidth: 1.5
            }
          ]
        },
        legend: {
          display: false
        }
      }
    };
  },
  watch: {
    timeSeries(newval, oldval) {
      if (newval === oldval) {
        return;
      }
      this.setTimeSeries();
      this.setYAxesLimitData();
      setTimeout(() => {
        this.renderChart(this.dataCollection, this.options);
      }, 300);
    }
  },
  methods: {
    setTimeSeries() {
      this.dataCollection.labels = this.timeSeries.map(ts => ts.jst);
      this.dataCollection.datasets[0].data = this.timeSeries.map(
        ts => ts.value
      );
      this.options.scales.xAxes[0].ticks.min = this.minDate.toDate();
      this.options.scales.xAxes[0].ticks.max = this.maxDate.toDate();
      this.options.annotation.annotations[0].value = this.baseDate.toDate();
    },
    setYAxesLimitData() {
      const rains = this.dataCollection.datasets[0].data;
      const maxLevel = _.max(rains.filter(r => r !== null));
      if (maxLevel > 5) {
        this.options.scales.yAxes[0].ticks.max = Math.floor(
          parseFloat(maxLevel) + 1
        );
      }
    }
  }
};
</script>

<style scoped></style>
