const staticConfig = {
  tileServer: {
    url: "https://cyberjapandata.gsi.go.jp"
  }
};

const apiBaseUrl = {
  development: "https://stage-api.suibou-gunma.jp/api",
  production: "https://api.suibou-gunma.jp/api"
};

export { staticConfig, apiBaseUrl };
