<script>
import { Bar } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
import { STAGES } from "../../../enums/Type";
Chart.plugins.register(ChartAnnotationsPlugin);
import _ from "lodash";

export default {
  extends: Bar,
  name: "StageChart",
  props: {
    selectedObservatory: [Object, Array],
    timeSeries: [Object, Array],
    baseDate: Object,
    minDate: Object,
    maxDate: Object
  },
  data() {
    return {
      landform: [],
      dataCollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "水位",
            unit: "m",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "level",
            borderWidth: 2,
            borderColor: "#01163F",
            backgroundColor: "#01163F",
            pointBorderColor: "#01163F",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "line",
            label: "水位",
            unit: "m",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "level",
            borderWidth: 3,
            borderColor: "#3734dc",
            backgroundColor: "#3734dc",
            pointBorderColor: "#3734dc",
            borderDash: [10, 5],
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          }
        ]
      },
      options: {
        layout: {
          padding: {
            right: 0,
            left: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              return (
                label +
                " : " +
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] +
                " " +
                unit
              );
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "level",
              gridLines: {
                display: true
              },
              ticks: {
                max: 20,
                min: 0,
                stepSize: 1,
                callback: tick => {
                  return ("        " + parseFloat(tick).toFixed(1)).slice(-6);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              type: "time",
              gridLines: {
                display: false
              },
              time: {
                unit: "hour",
                unitStepSize: 1,
                tooltipFormat: "MM/DD HH:mm",
                displayFormats: {
                  minute: "HH:mm",
                  hour: "HH:mm",
                  day: "HH:mm",
                  week: "HH:mm",
                  month: "HH:mm"
                }
              },
              ticks: {
                min: this.minDate.toDate(),
                max: this.maxDate.toDate(),
                display: true,
                autoSkip: true
              },
              categoryPercentage: 1.0,
              barPercentage: 1.0
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        groundDataset: [],
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "box",
              drawTime: "beforeDatasetsDraw",
              yScaleID: "level",
              borderColor: "rgba(0,0,0,0)",
              yMin: 0,
              yMax: 0,
              backgroundColor: "#3D8AFF"
            },
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "02:00",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        }
      }
    };
  },
  computed: {
    levelUnit() {
      return this.selectedObservatory.kind === 9 ? "TP.m" : "m";
    }
  },
  watch: {
    timeSeries(newval, oldval) {
      if (newval !== oldval) {
        this.load();
      }
    }
  },
  mounted() {
    this.addPlugin({
      id: "horizontalLine",
      beforeDatasetsDraw: chart => {
        const groundDataset = chart.config.options.groundDataset;
        if (groundDataset.length > 0) {
          const ctxPlugin = chart.chart.ctx;
          const xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
          const yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
          const minGround = _.minBy(groundDataset, o => {
            return o[0];
          });
          const maxGround = _.maxBy(groundDataset, o => {
            return o[0];
          });
          ctxPlugin.strokeStyle = "red";
          ctxPlugin.beginPath();
          ctxPlugin.moveTo(xAxe.left, yAxe.bottom);
          for (let i = 0; i < groundDataset.length; i++) {
            let x = groundDataset[i][0] - minGround[0];
            x = (x / (maxGround[0] - minGround[0])) * xAxe.width + xAxe.left;
            let y = yAxe.max - yAxe.min - (groundDataset[i][1] - yAxe.min);
            y = (y / (yAxe.max - yAxe.min)) * yAxe.height + yAxe.top;
            ctxPlugin.lineTo(x, y);
          }
          ctxPlugin.lineTo(xAxe.right, yAxe.bottom);
          ctxPlugin.lineTo(xAxe.left, yAxe.bottom);
          ctxPlugin.closePath();
          ctxPlugin.fillStyle = "#f1eeea";
          ctxPlugin.fill();
        }
      }
    });
  },
  methods: {
    load() {
      this.setTimeSeries();
      this.setYAxesLimitData();
      this.setWarningLevelStrokeData();
      this.setWaterBoxData();
      setTimeout(() => {
        this.renderChart(this.dataCollection, this.options);
      }, 300);
    },
    setTimeSeries() {
      // timeseries
      this.dataCollection.labels = this.timeSeries.map(ts => ts.jst);

      // reals
      const reals = this.timeSeries
        .filter(ts => ts.type === "real")
        .map(ts => ts.value);
      this.dataCollection.datasets[0].data = reals;

      // forecast
      const forecasts = this.timeSeries
        .filter(ts => ts.type === "forecast")
        .map(ts => ts.value);
      const empyts = reals.map(() => null);
      this.dataCollection.datasets[1].data = empyts.concat(forecasts);

      // date range
      this.options.scales.xAxes[0].ticks.min = this.minDate.toDate();
      this.options.scales.xAxes[0].ticks.max = this.maxDate.toDate();

      // current datetime
      this.options.annotation.annotations[1].value = this.baseDate.toDate();

      // unit
      this.dataCollection.datasets[0].unit = this.levelUnit;
      this.dataCollection.datasets[1].unit = this.levelUnit;

      // landform
      this.landform = [];
      if (
        this.selectedObservatory.landform &&
        this.selectedObservatory.landform.length > 0
      ) {
        this.landform = this.selectedObservatory.landform;
        this.options.groundDataset = this.landform;
      }
    },
    setYAxesLimitData() {
      const levels = this.timeSeries
        .filter(ts => ts.value !== null)
        .map(ts => ts.value);
      // Y座標側のscaleを設定
      if (this.landform.length > 0) {
        const landformYMin = _.minBy(this.landform, o => {
          return o[1];
        });
        const landformYMax = _.maxBy(this.landform, o => {
          return o[1];
        });
        if (landformYMin) {
          const riverLevelMin = Math.floor(_.min(levels) - 1);
          const landformYMinValue = Math.floor(landformYMin[1] - 1);
          if (landformYMinValue > riverLevelMin) {
            this.options.scales.yAxes[0].ticks.min = riverLevelMin;
            this.options.annotation.annotations[0].yMin = riverLevelMin;
          } else {
            this.options.scales.yAxes[0].ticks.min = landformYMinValue;
            this.options.annotation.annotations[0].yMin = landformYMinValue;
          }
        }
        if (landformYMax) {
          const riverLevelMax = Math.floor(_.max(levels) + 1);
          const landformYMaxValue = Math.floor(landformYMax[1] + 1);
          if (landformYMaxValue < riverLevelMax) {
            this.options.scales.yAxes[0].ticks.max = riverLevelMax;
          } else {
            this.options.scales.yAxes[0].ticks.max = landformYMaxValue;
          }
        }
      } else {
        let minLevel = _.min(levels);
        const maxLevel = _.max(levels);

        if (isNaN(minLevel)) {
          if (this.selectedObservatory.kind === 9) {
            minLevel =
              this.selectedObservatory.startStage !== undefined
                ? this.selectedObservatory.startStage
                : this.selectedObservatory.dangerousLevel;
          } else {
            minLevel =
              this.selectedObservatory.standbyLevel !== undefined
                ? this.selectedObservatory.standbyLevel
                : this.selectedObservatory.warningLevel;
          }
          minLevel = minLevel - 2;
        } else {
          minLevel = Math.floor(minLevel - 1);
        }

        this.options.scales.yAxes[0].ticks.min = minLevel;
        this.options.scales.yAxes[0].ticks.max = maxLevel + 2;
      }
      const tmax = this.options.scales.yAxes[0].ticks.max;
      if (this.selectedObservatory.outbreakLevel !== undefined) {
        if (this.selectedObservatory.outbreakLevel > tmax) {
          this.options.scales.yAxes[0].ticks.max = Math.floor(
            this.selectedObservatory.outbreakLevel + 2
          );
        }
      } else if (this.selectedObservatory.dangerousLevel !== undefined) {
        if (this.selectedObservatory.dangerousLevel > tmax) {
          this.options.scales.yAxes[0].ticks.max = Math.floor(
            this.selectedObservatory.dangerousLevel + 2
          );
        }
      }
    },
    setWarningLevelStrokeData() {
      const levels = [];
      if (this.selectedObservatory.kind === 9) {
        levels.push({
          color: STAGES.START.color,
          value: this.selectedObservatory.startStage
        });
      } else {
        levels.push({
          color: STAGES.STANDBY.color,
          value: this.selectedObservatory.standbyLevel
        });
        levels.push({
          color: STAGES.WARNING.color,
          value: this.selectedObservatory.warningLevel
        });
        levels.push({
          color: STAGES.EVACUATION.color,
          value: this.selectedObservatory.evacuationLevel
        });
      }
      levels.push({
        color: STAGES.DANGEROUS.color,
        value: this.selectedObservatory.dangerousLevel
      });
      levels.push({
        color: STAGES.OUTBREAK.color,
        value: this.selectedObservatory.outbreakLevel
      });
      levels.forEach(info => {
        if (info.value === undefined || info.value === null) {
          return;
        }
        this.options.annotation.annotations.push(
          this.getAnnotationObject(info)
        );
      });
    },
    getAnnotationObject(warningInfo) {
      return {
        type: "line",
        mode: "horizontal",
        scaleID: "level",
        value: warningInfo.value,
        borderColor: warningInfo.color,
        borderWidth: 2,
        label: {
          backgroundColor: "rgba(0,0,0,0)",
          yAdjust: -6,
          fontSize: 10,
          fontColor: "black",
          position: "right",
          enabled: true
        }
      };
    },
    setWaterBoxData() {
      const reals = this.dataCollection.datasets[0].data;
      if (reals.length === 0) {
        return;
      }
      let level = null;
      for (let i = reals.length - 1; i > reals.length - 60; i--) {
        if (reals[i] !== null && reals[i] !== undefined) {
          level = reals[i];
          break;
        }
      }
      if (level === null) {
        return;
      }
      this.options.annotation.annotations[0].yMin = this.options.scales.yAxes[0].ticks.min;
      this.options.annotation.annotations[0].yMax = level;
    }
  }
};
</script>

<style scoped></style>
