<template>
  <div class="manual">
    <v-card class="d-flex align-center" color="#3a1e87" tile>
      <h3 class="white--text pa-2">ご利用に際して</h3>
      <v-btn small icon class="ml-auto mr-2" dark>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-btn>
    </v-card>
    <div class="pa-5">
      <h2>「かわみるぐんま」について</h2>
      <div class="body-2">
        国土交通省と群馬県が公開している河川の水位情報やライブカメラ画像のほか、気象庁が公開している予測降雨量データなど、水災害に関する各種防災情報を集約し、ひとつのウェブサイトで閲覧できるシステムです。
        ご利用方法についてはこちらをご覧ください。
      </div>
      <div class="mt-1">
        <a
          href="https://s3.ap-northeast-1.amazonaws.com/ctie.gnm-pref.web/static/manual/manual.pdf"
          target="_blank"
          >かわみるぐんま ご利用マニュアル（PDF）</a
        >
        <br />
        <a href="https://youtu.be/_5HA29_YVRY" target="_blank">
          かわみるぐんま 動画マニュアル（YouTube）
        </a>
      </div>

      <h2 class="mt-4">利用における注意事項</h2>
      <div class="body-2">
        本システムで提供する観測情報は、無人観測所から送られてくるデータをただちにお知らせする目的で作られています。
        そのため、観測機器の故障や通信異常等による異常データがそのまま表示されてしまう可能性がありますのでご注意ください。
      </div>

      <h2 class="mt-4">リアルタイムデータ提供元</h2>
      <v-btn
        class="my-2"
        small
        color="primary"
        to="/observatories"
        target="_blank"
        outlined
      >
        観測所一覧を表示
      </v-btn>
      <template v-for="item in providers">
        <div :key="item.name">
          <h4 class="mt-1">{{ item.name }}</h4>
          <div class="body-2 mb-3">{{ item.description }}</div>
        </div>
      </template>

      <h2 class="mt-4">リンク集</h2>
      <ul>
        <li>
          <a href="https://www.pref.gunma.jp/05/a5510001.html" target="_blank"
            >群馬県の防災ページ</a
          >
        </li>
        <li>
          <a
            href="https://www.river-gunma.jp/gunma/top/10/1_0_1_0.html"
            target="_blank"
            >群馬県水位雨量情報システム</a
          >
        </li>
        <li>
          <a href="http://www.kendobousai-gunma.jp/" target="_blank"
            >群馬県県土整備部防災情報</a
          >
        </li>
        <li>
          <a href="https://www.river.go.jp/" target="_blank"
            >川の防災情報（国土交通省）</a
          >
        </li>
      </ul>
    </div>
    <v-card class="text-center mt-3 mb-5" tile flat>
      <v-btn @click="close()">
        <v-icon dark>
          mdi-close
        </v-icon>
        閉じる
      </v-btn>
    </v-card>
    <div class="pa-2 footer text-right">
      群馬県県土整備部河川課<br />
      〒371-8570 前橋市大手町1-1-1<br />
      (027)226-3619<br />
    </div>
  </div>
</template>

<script>
export default {
  name: "ManualPublic",
  data() {
    return {
      providers: [
        {
          name: "雨量観測所",
          description:
            "群馬県、国土交通省が設置した雨量観測地点の雨量情報を表示します。"
        },
        {
          name: "水位観測所",
          description:
            "群馬県、国土交通省が設置した水位観測地点（テレメータ水位計、危機管理型水位計、ダム水位計）の水位情報を表示します。"
        },
        {
          name: "河川ライブカメラ",
          description:
            "群馬県、国土交通省が設置した河川カメラ監視地点の静止画像・動画を表示します。"
        },
        {
          name: "予測降雨",
          description:
            "気象庁が発表する高解像度降水ナウキャスト、降水短時間予報、メソ数値予報モデルGPV（MSM）を組み合わせて表示しています。"
        },
        {
          name: "洪水キキクル",
          description:
            "気象庁洪水キキクル（洪水警報の危険度分布）は、大雨による中小河川（水位周知河川及びその他河川）の洪水災害発生の危険度の高まりを５段階に色分けして地図上に示したものです。危険度の判定には３時間先までの流域雨量指数の予測値を用いており、中小河川の特徴である急激な増水による危険度の高まりを事前に確認することができます。"
        },
        {
          name: "土砂キキクル",
          description:
            "気象庁土砂キキクル（大雨警報（土砂災害）の危険度分布）は、大雨による土砂災害発生の危険度の高まりを、地図上で１km四方の領域（メッシュ）ごとに５段階に色分けして示す情報です。"
        }
      ]
    };
  },
  created() {
    document.title = "ご利用に際して";
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateWindowHeight();
    });
    window.addEventListener("resize", this.calculateWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateWindowHeight);
  },
  methods: {
    close() {
      window.close();
    },
    calculateWindowHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }
};
</script>

<style lang="scss" scoped>
.manual {
  background-color: white;
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow-y: auto;
  .v-data-table {
    th {
      background-color: #cee0ff;
    }
    border: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .footer {
    background-color: lightgrey;
    width: 100%;
    font-size: 12px;
  }
}
</style>
