<template>
  <l-geo-json
    v-if="riskline.length !== 0"
    :geojson="selectedDateRiskData.lines"
    :options="{ style: styleFunction }"
  ></l-geo-json>
</template>

<script>
import { LGeoJson } from "vue2-leaflet";
import moment from "moment";
export default {
  name: "RiskLineLayer",
  inject: {
    baseMapData: {
      default: []
    }
  },
  components: {
    LGeoJson
  },
  data() {
    return {
      initGeojson: [],
      geojson: []
    };
  },
  computed: {
    riskline() {
      return this.$store.getters.riskline;
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedDateRiskData() {
      const selectedDateRiskData = this.riskline.find(o => {
        return this.selectedDate.isSame(moment.utc(o.date, "YYYY/MM/DD HH:mm"));
      });
      if (!selectedDateRiskData) {
        return this.riskline[0];
      } else {
        return selectedDateRiskData;
      }
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    baseDate() {
      if (this.riskline.length > 0) {
        this.loadData();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
  beforeDestroy() {
    this.$store.commit("SET_USAGE_GUIDE", {
      usageGuide: {
        title: null,
        colors: []
      }
    });
  },
  methods: {
    loadData() {
      this.$store.dispatch("FETCH_RISKLINE");
      this.$store.commit("SET_USAGE_GUIDE", [
        {
          title: "河川危険度",
          colors: [
            { color: "black", value: "この時間で越水の恐れ" },
            { color: "red", value: "1時間以内に越水の恐れ" },
            { color: "yellow", value: "6時間以内に越水の恐れ" }
          ]
        },
        {
          title: "リスク(m)",
          colors: [
            { color: "rgb(0,112,255)", value: "2.0 ~" },
            { color: "rgb(45,193,223)", value: "1.5 ~ 2.0" },
            { color: "rgb(115,255,222)", value: "1.0 ~ 1.5" },
            { color: "rgb(67,230,0)", value: "0.5 ~ 1.0" },
            { color: "rgb(255,255,0)", value: "0.05 ~ 0.5" }
          ]
        }
      ]);
    },
    styleFunction(feature) {
      const style = {
        weight: 2,
        color: "#85bcff",
        opacity: 1,
        fillColor: "#85bcff",
        fillOpacity: 0.2,
        "fill-outline-color": "rgba(255,255,255,0.0)",
        outlineColor: "blue"
      };
      if (feature.properties.level && feature.properties.level === 1) {
        style.weight = 10;
        style.color = "yellow";
        style.fillColor = "yellow";
      } else if (feature.properties.level && feature.properties.level === 2) {
        style.weight = 10;
        style.color = "#ff0000";
        style.fillColor = "#ff0000";
      } else if (feature.properties.level && feature.properties.level === 3) {
        style.weight = 10;
        style.color = "black";
        style.fillColor = "black";
      }
      return style;
    }
  }
};
</script>

<style scoped></style>
