<template>
  <div class="manual">
    <v-card class="d-flex align-center" color="#3a1e87" tile>
      <h3 class="white--text pa-2">ご利用に際して</h3>
      <v-btn small icon class="ml-auto mr-2" dark>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-btn>
    </v-card>
    <div class="pa-5">
      <h2 class="mb-2">操作マニュアル</h2>
      <div>
        <a
          href="https://s3.ap-northeast-1.amazonaws.com/ctie.gnm-pref.web/static/docs/manual.pdf"
          target="_blank"
        >
          モデル概要及びシステム操作説明資料(PDF)
        </a>
      </div>

      <h2 class="mt-8 mb-2">システム説明動画</h2>
      <div class="mb-2">
        <a href="https://youtu.be/POUpB56I9YU" target="_blank">
          ① リアルタイム水害リスク情報システム概要
        </a>
      </div>
      <div class="mb-2">
        <a href="https://youtu.be/p5q-PkdwGrw" target="_blank"
          >② 水位・浸水予測モデル説明
        </a>
      </div>
      <div>
        <a href="https://youtu.be/TBknndOQN5w" target="_blank">
          ③ システム操作マニュアル
        </a>
      </div>

      <h2 class="mt-8 mb-2">観測所一覧</h2>
      <v-btn
        class="my-2"
        small
        color="primary"
        to="/observatories"
        target="_blank"
        outlined
      >
        観測所一覧を表示
      </v-btn>
    </div>
    <v-card class="text-center mt-8" tile flat>
      <v-btn @click="close()">
        <v-icon dark>
          mdi-close
        </v-icon>
        閉じる
      </v-btn>
    </v-card>
    <v-footer app>
      群馬県県土整備部河川課<br />
      〒371-8570 前橋市大手町1-1-1<br />
      (027)226-3619<br />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "ManualPrivate",
  data() {
    return {
      //
    };
  },
  created() {
    document.title = "ご利用に際して";
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateWindowHeight();
    });
    window.addEventListener("resize", this.calculateWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateWindowHeight);
  },
  methods: {
    close() {
      window.close();
    },
    calculateWindowHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }
};
</script>

<style lang="scss" scoped>
.manual {
  background-color: white;
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow-y: auto;
}
</style>
