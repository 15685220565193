<template>
  <div>
    <v-row v-if="usageGuide && !Array.isArray(usageGuide)" no-gutters>
      <v-col cols="12" class="color-legend ma-2">
        <div class="unit" v-if="usageGuide.title">{{ usageGuide.title }}</div>
        <div class="legend">
          <div
            v-for="(o, index) in usageGuide.colors"
            :key="index"
            :class="o.value !== '' ? 'color' : 'empty-color'"
            :style="{ backgroundColor: o.color }"
          >
            <span>
              {{ o.value !== "" ? o.value : "空" }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col
        cols="12"
        v-for="(usageItem, index) in usageGuide"
        class="color-legend ma-2"
        :key="index"
      >
        <div class="unit" v-if="usageItem.title">{{ usageItem.title }}</div>
        <div class="legend">
          <div
            v-for="(o, index) in usageItem.colors"
            :key="index"
            class="color"
            :style="{ backgroundColor: o.color }"
          >
            {{ o.value }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UsageGuidePanel",
  computed: {
    usageGuide() {
      return this.$store.getters.usageGuide;
    },
    hazardMapUsageGuide() {
      return this.$store.getters.hazardMapUsageGuide;
    }
  }
};
</script>

<style scoped lang="scss">
.color-legend {
  clear: both;
}
.legend {
  width: fit-content;
  text-align: right;
  float: right;
}
.unit {
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  white-space: pre;
  text-shadow: 1px 1px 0 #ffffff, -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
    -1px -1px 0 #ffffff;
}
.color {
  color: #ffffff;
  text-shadow: 1px 1px 0 #000000, -1px 1px 0 #000000, 1px -1px 0 #000000,
    -1px -1px 0 #000000;
  font-weight: bold;
  font-size: 10px;
  min-width: 10px;
  text-align: center;
  line-height: 1;
  padding: 2px 5px;
}
.color-rain-normal {
  color: #000000;
  font-weight: bold;
  font-size: 10px;
  min-width: 10px;
  text-align: center;
  line-height: 1;
  padding: 2px 5px;
}

.empty-color {
  color: #ffffff;
  text-shadow: 1px 1px 0 #000000, -1px 1px 0 #000000, 1px -1px 0 #000000,
    -1px -1px 0 #000000;
  font-weight: bold;
  font-size: 10px;
  min-width: 10px;
  text-align: center;
  line-height: 1;
  padding: 2px 10px;

  span {
    opacity: 0;
  }
}
</style>
