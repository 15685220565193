import Vue from "vue";
import axios from "axios";
import { apiBaseUrl } from "../config";
import router from "../router";
import { isPrivate } from "./common";

export const get = async (path, params) => {
  const headers = {
    "x-api-key": "yzkQpdAiAT1mzThZzX6U54vnTuo9GgWQ3N9xOBy3"
  };
  if (isPrivate === true) {
    const token = Vue.$cookies.get("access_token");
    if (token) {
      headers["x-auth-token"] = token;
    }
  }
  const apiAxios = axios.create({
    responseType: "json",
    headers: headers,
    baseURL: apiBaseUrl[process.env.NODE_ENV]
  });
  return apiAxios
    .get(path, {
      params
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.error(err);
      if (
        isPrivate === true &&
        router.currentRoute.path != "/login" &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        router.replace("/login");
      }
      return null;
    });
};
