<template>
  <div>
    <v-card class="pa-2 mb-1" color="black" flat tile>
      <v-carousel
        hide-delimiters
        hide-delimiter-background
        v-model="currentImageIndex"
        height="300"
        :continuous="false"
      >
        <v-carousel-item v-for="(info, index) in images" :key="index">
          <v-img height="300" :src="info.url" contain />
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <div class="image-list mb-4">
      <div
        v-for="(image, index) in images"
        :key="index"
        :class="{ 'current-image': index === currentImageIndex }"
        class="image-list-item"
        @click="updateCurrentImage(index)"
      >
        <v-img width="100%" :src="image.url"></v-img>
        <div class="times">
          {{
            moment
              .utc(image.dateTime, "YYYY/MM/DD HH:mm")
              .local()
              .format("HH:mm")
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CameraDetail",
  data() {
    return {
      moment,
      currentImageIndex: 0
    };
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    images() {
      if (this.selectedObservatory.type === 4) {
        if (this.selectedObservatory.cameraInfo === undefined) {
          return [];
        } else {
          return this.selectedObservatory.cameraInfo.images.slice(-6);
        }
      } else {
        if (this.selectedObservatory.imageInfo === undefined) {
          return [];
        } else {
          return this.selectedObservatory.imageInfo.slice(-6);
        }
      }
    }
  },
  methods: {
    updateCurrentImage(index) {
      this.currentImageIndex = index;
    }
  },
  mounted() {
    this.currentImageIndex = this.images.length - 1;
  }
};
</script>

<style lang="scss">
.image-list {
  display: flex;
  flex-direction: row;
  .image-list-item {
    min-width: 10%;
    max-width: 30%;
    border-top: 1px solid rgb(66, 66, 66);
    border-bottom: 1px solid rgb(66, 66, 66);
    border-right: 1px solid rgb(66, 66, 66);
    cursor: pointer;
    background-color: white;
    display: inline-block;
    .times {
      text-align: center;
      padding: 2px 0;
      line-height: 1;
      width: 100%;
      font-size: 12px;
      font-weight: normal;
      color: rgb(65, 65, 65);
      background-color: white;
    }
  }
  .image-list-item:first-child {
    border-left: 1px solid rgb(66, 66, 66);
  }
  .current-image {
    .times {
      color: white;
      background-color: #0d47a1;
    }
  }
}
</style>
