<template>
  <div class="observatory-control">
    <div>
      <div class="d-flex" style="pointer-events: painted;">
        <v-btn small color="secondary mr-1" @click="changeExpand()">
          <div>種別</div>
          <v-icon>{{
            isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
        <v-btn small color="secondary" @click="showMonitor()">
          <v-icon>mdi-monitor-small</v-icon>
        </v-btn>
      </div>
      <div class="sub-control" v-if="isExpanded">
        <v-row no-gutters align="center">
          <v-col>
            <v-checkbox
              class="checkbox"
              hide-details
              dense
              v-model="enabledObservatories"
              :label="OBSERVATORY.STAGE.name"
              :value="OBSERVATORY.STAGE.code"
              @change="onChangeFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="7">
            <v-icon size="16">mdi-triangle</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col cols="12">
            <v-radio-group
              v-model="selectWaterItem"
              dense
              class="water-radio-group"
              :disabled="disabledWaterItems"
              @change="onChangeFilters"
            >
              <v-radio
                v-for="wi in waterItems"
                :key="wi.key"
                :label="wi.label"
                :value="wi.key"
                class="ma-0"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col>
            <v-checkbox
              class="checkbox"
              hide-details
              dense
              v-model="enabledObservatories"
              :label="OBSERVATORY.RAIN.name"
              :value="OBSERVATORY.RAIN.code"
              @change="onChangeFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="7">
            <v-icon size="16">mdi-checkbox-blank-circle</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-btn-toggle
            class="ml-8 my-2"
            v-model="rainInterval"
            mandatory
            dense
            @change="changeRainInterval"
          >
            <v-btn x-small value="min10">
              10分
            </v-btn>
            <v-btn x-small value="min60">
              時間
            </v-btn>
            <v-btn x-small value="total">
              累計
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <v-row no-gutters align="center">
          <v-col>
            <v-checkbox
              class="checkbox"
              hide-details
              dense
              v-model="enabledObservatories"
              :label="OBSERVATORY.DAM.name"
              :value="OBSERVATORY.DAM.code"
              @change="onChangeFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="7">
            <v-icon size="18">mdi-car-windshield</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col>
            <v-checkbox
              class="checkbox"
              hide-details
              dense
              v-model="enabledObservatories"
              :label="OBSERVATORY.CAMERA.name"
              :value="OBSERVATORY.CAMERA.code"
              @change="onChangeFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="7">
            <v-icon size="18">mdi-camera</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col>
            <v-checkbox
              class="checkbox"
              hide-details
              dense
              v-model="enabledObservatories"
              :label="OBSERVATORY.LIVECAMERA.name"
              :value="OBSERVATORY.LIVECAMERA.code"
              @change="onChangeFilters"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-icon size="18">mdi-camera-iris</v-icon>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-checkbox
          class="checkbox"
          hide-details
          dense
          color="red"
          v-model="showRainRadar"
          label="降雨レーダ"
          @change="onChangeRainRadar"
        ></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { OBSERVATORY, STAGES } from "../../../enums/Type";

export default {
  name: "ObservatoryControl",
  data: () => ({
    rainInterval: "min10",
    OBSERVATORY,
    enabledObservatories: [],
    histories: [],
    waterItems: [
      { key: 0, label: "すべて", kinds: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { key: 1, label: "テレメータ水位計", kinds: [1, 2, 3, 4, 5, 6] },
      { key: 2, label: "危機管理型水位計", kinds: [9] },
      { key: 3, label: "水位周知/洪水予報基準点", kinds: [5, 6] }
    ],
    selectWaterItem: 0,
    isExpanded: false,
    showRainRadar: false
  }),
  computed: {
    disabledWaterItems() {
      return this.enabledObservatories.indexOf(OBSERVATORY.STAGE.code) === -1;
    },
    isShowHazardMapControl() {
      return this.$store.getters.isShowHazardMapControl;
    }
  },
  watch: {
    enabledObservatories() {
      this.showColorLegend();
    },
    isShowHazardMapControl() {
      if (this.isShowHazardMapControl && this.isExpanded) {
        this.changeExpand();
      }
    }
  },
  created() {
    this.enabledObservatories = this.$store.getters.riskSubControl.observatory;
  },
  mounted() {
    this.onChangeFilters();
    this.showColorLegend();
    setTimeout(() => {
      this.showRainRadar = true;
      this.$store.commit("SET_IS_SHOW_OBSERVATORY_RADAR", this.showRainRadar);
    }, 1000);
    this.rainInterval = this.$store.getters.rainInterval;
  },
  beforeDestroy() {
    this.$store.commit("SET_IS_SHOW_OBSERVATORY_RADAR", false);
  },
  methods: {
    onChangeFilters() {
      this.$store.commit("SET_RISK_SUB_CONTROL", {
        observatory: this.enabledObservatories,
        waterKinds: this.waterItems[this.selectWaterItem].kinds
      });
    },
    showColorLegend() {
      const legends = [];
      if (this.enabledObservatories.includes(OBSERVATORY.STAGE.code)) {
        legends.push({
          title: "水位",
          colors: [
            { color: STAGES.OUTBREAK.color, value: STAGES.OUTBREAK.label },
            { color: STAGES.DANGEROUS.color, value: STAGES.DANGEROUS.label },
            { color: STAGES.EVACUATION.color, value: STAGES.EVACUATION.label },
            { color: STAGES.WARNING.color, value: STAGES.WARNING.label },
            { color: STAGES.STANDBY.color, value: STAGES.STANDBY.label },
            { color: STAGES.START.color, value: STAGES.START.label },
            { color: STAGES.NORMAL.color, value: STAGES.NORMAL.label },
            { color: STAGES.DEFICIT.color, value: STAGES.DEFICIT.label }
          ]
        });
      }
      if (this.enabledObservatories.includes(OBSERVATORY.RAIN.code)) {
        let colors = [];
        if (this.rainInterval === "min10") {
          colors = [
            { color: "#800080", value: "20㎜以上" },
            { color: "#ff0000", value: "10㎜以上" },
            { color: "#d88100", value: "5㎜以上" },
            { color: "#1e42af", value: "1㎜以上" },
            { color: "#ffffff", value: "1㎜未満" },
            { color: "#aaaaaa", value: "休止・欠測等" }
          ];
        } else {
          colors = [
            { color: "#800080", value: "80㎜以上" },
            { color: "#ff0000", value: "50㎜以上" },
            { color: "#d88100", value: "30㎜以上" },
            { color: "#1e42af", value: "20㎜以上" },
            { color: "#2a7aaa", value: "1㎜以上" },
            { color: "#ffffff", value: "1㎜未満" },
            { color: "#aaaaaa", value: "休止・欠測等" }
          ];
        }
        legends.push({
          title: "雨量",
          colors: colors
        });
      }
      this.$store.commit("SET_USAGE_GUIDE", legends);
    },
    changeExpand() {
      this.isExpanded = !this.isExpanded;
      this.$store.commit(
        "SET_IS_SHOW_OBSERVATORY_SUB_CONTROL",
        this.isExpanded
      );
    },
    onChangeRainRadar() {
      this.$store.commit("SET_IS_SHOW_OBSERVATORY_RADAR", this.showRainRadar);
    },
    changeRainInterval() {
      this.$store.commit("SET_RAIN_INTERVAL", this.rainInterval);
      this.showColorLegend();
    },
    showMonitor() {
      this.$store.commit("SET_IS_SHOW_OBSERVATORY_MONITOR", true);
    }
  }
};
</script>
<style lang="scss">
.water-radio-group {
  margin: 0 0 0 10px;
  height: 100px;
  .v-label {
    font-size: 12px;
  }
}
.checkbox {
  .v-label {
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
.observatory-control {
  width: 195px;
  .v-input--selection-controls {
    margin-top: 0;
  }
  .sub-control {
    pointer-events: painted;
    margin-top: 10px;
    padding: 3px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
}
</style>
