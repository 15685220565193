<template>
  <div class="address-search-control">
    <v-text-field
      v-model="keyword"
      placeholder="  住所/地点検索"
      type="text"
      background-color="rgba(255, 255, 255, 0.85)"
      @keydown.enter="search($event.keyCode)"
      class="ma-0 pa-0"
      hide-details
    >
      <template v-slot:append-outer>
        <v-btn small color="secondary" @click="search()">
          <v-icon size="24">mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-dialog v-model="isShowDialog" persistent scrollable max-width="400">
      <v-card>
        <v-card-title class="subtitle-2">{{ keyword }}</v-card-title>
        <v-card-text>
          <v-list dense>
            <template v-for="(location, i) in locations">
              <v-list-item :key="i" link @click="move(location)">
                <v-list-item-icon>
                  <v-icon small>{{ location.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="location.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="`d-${i}`"></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowDialog = false">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import L from "leaflet";
import { OBSERVATORY } from "../../enums/Type";
import { Risk } from "../../enums/Risk";

export default {
  name: "AddressSearchControl",
  data() {
    return {
      keyword: "",
      isShowDialog: false,
      locations: [],
      currentLocation: null,
      currentMarker: null
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return this.$store.getters.leafletMap.getZoom();
    },
    selectedRiskKey() {
      return this.$store.getters.selectedRisk.key;
    }
  },
  methods: {
    async search(keyCode) {
      this.locations = [];
      // 日本語入力中のキーダウンは処理しない
      if (keyCode !== undefined && keyCode !== 13) {
        return;
      }
      if (this.keyword == "") {
        return;
      }
      const params = {
        keyword: this.keyword
      };
      const res = await axios
        .create({
          responseType: "json",
          baseURL: "https://common-api.suibou-gunma.jp"
        })
        .get("/location", {
          params
        });
      this.locations = res.data.locations.map(location => {
        location.icon = "mdi-map-marker";
        switch (location.type) {
          case OBSERVATORY.RAIN.code:
            location.icon = "mdi-checkbox-blank-circle";
            break;
          case OBSERVATORY.STAGE.code:
            location.icon = "mdi-triangle";
            break;
          case OBSERVATORY.CAMERA.code:
            location.icon = "mdi-camera";
            break;
          case OBSERVATORY.LIVECAMERA.code:
            location.icon = "mdi-camera-iris";
            break;
          case -2:
            location.icon = "mdi-waves";
            break;
        }
        return location;
      });
      if (this.locations.length === 0) {
        this.$toasted.show(
          "群馬県周辺に該当する位置情報が見つかりませんでした"
        );
      } else {
        this.isShowDialog = true;
      }
    },
    move(location) {
      if (this.currentMarker !== null) {
        this.map.removeLayer(this.currentMarker);
      }
      this.isShowDialog = false;
      const latLng = [Number(location.lat), Number(location.lng)];
      // marker
      if (
        location.type === undefined ||
        location.type < 0 ||
        this.selectedRiskKey !== Risk.OBSERVATORY.key
      ) {
        this.currentMarker = L.marker(latLng);
        const icon = this.currentMarker.options.icon;
        icon.options.iconSize = [18, 28];
        this.currentMarker.setIcon(icon);
        this.currentMarker.addTo(this.map);
        this.currentMarker
          .bindTooltip(location.name, {
            direction: "top",
            offset: L.point(-15, -10),
            className: "location-tooltip"
          })
          .openTooltip();
      }
      this.map.panTo(latLng);
      if (this.zoom < 15) {
        setTimeout(() => {
          this.map.setZoom(15);
        }, 300);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.address-search-control {
  pointer-events: painted;
  .v-text-field {
    max-width: 180px;
    font-size: 1em;
  }
}
</style>

<style lang="scss">
.location-tooltip {
  color: #396cb9 !important;
}
</style>
