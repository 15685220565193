<template>
  <div class="observatories">
    <v-card class="d-flex align-center" color="#3a1e87" tile>
      <h3 class="white--text pa-2">観測所一覧</h3>
      <v-btn small icon class="ml-auto mr-2" dark>
        <v-icon @click="close()">mdi-close</v-icon>
      </v-btn>
    </v-card>
    <div class="d-flex ma-2">
      <template v-for="t in types">
        <v-btn
          :key="t.code"
          small
          @click="fetch(t)"
          class="mr-2"
          :color="btnColor(t.code)"
        >
          {{ t.name }}
        </v-btn>
      </template>
    </div>
    <v-simple-table dense height="calc(100vh - 100px)" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="title-no">No</th>
            <th class="text-left">観測所名</th>
            <th class="text-left">管理者</th>
            <th class="text-left" v-show="targetCode === 4">公開状況</th>
            <th class="text-left" v-show="targetCode !== 1">河川名</th>
            <th class="text-left">所在地</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="row.id">
            <td class="text-right">{{ index + 1 }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.manager }}</td>
            <td v-show="targetCode === 4">{{ row.status }}</td>
            <td v-show="targetCode !== 1">{{ row.riverName }}</td>
            <td>{{ row.address }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Observatories.vue",
  data() {
    return {
      types: [
        { code: 1, name: "雨量", rows: [] },
        { code: 4, name: "水位", rows: [] },
        { code: 7, name: "ダム", rows: [] },
        { code: 30, name: "カメラ", rows: [] }
      ],
      targetCode: 1,
      rows: []
    };
  },
  created() {
    document.title = "観測所一覧";
    this.fetch(this.types[0]);
  },
  methods: {
    close() {
      window.close();
    },
    async fetch(_type) {
      this.targetCode = _type.code;
      if (_type.rows.length > 0) {
        this.rows = _type.rows;
        return;
      }
      const res = await axios
        .create({
          responseType: "json",
          baseURL: "https://common-api.suibou-gunma.jp"
        })
        .get("/observatories", {
          params: { type: this.targetCode }
        });
      _type.rows = res.data;
      this.rows = _type.rows;
    },
    btnColor(code) {
      return this.targetCode === code ? "primary" : "#f3f2f2";
    }
  }
};
</script>

<style lang="scss" scoped>
.observatories {
  thead th {
    font-size: 12px !important;
    padding: 3px 6px !important;
    min-width: 70px !important;
  }
  .title-no {
    max-width: 50px;
    min-width: 50px !important;
    width: 50px;
  }
  tbody td {
    font-size: 12px !important;
    padding: 3px 6px !important;
    white-space: nowrap;
  }
}
</style>
