<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="close()">
        <div class="modal-container">
          <div class="modal-body" style="max-height: 90vh; overflow-y: auto; ">
            <h6>表示データ</h6>
            <v-row no-gutters>
              <v-col
                :key="risk.index"
                cols="6"
                @click.once="selectRisk(risk)"
                v-for="risk in targetRisk"
              >
                <v-card
                  class="menu-button"
                  :class="{ active: risk.key === selectedRisk.key }"
                >
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-text">
                      <v-icon small>{{ risk.icon }}</v-icon>
                      {{ risk.title }}
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>

            <h6>地図</h6>
            <v-row dense no-gutters>
              <v-col
                :key="tile.index"
                cols="6"
                @click.once="selectBaseTile(tile)"
                v-for="tile in BaseTile"
              >
                <v-card
                  class="menu-button"
                  :class="{ active: tile.key === selectedBaseTile.key }"
                >
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-text pl-2">
                      {{ tile.string }}
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>

            <h6>ハザードマップ</h6>
            <v-row dense no-gutters>
              <v-col
                :key="key"
                cols="6"
                @click="selectHazardMap(key, feature)"
                v-for="(feature, key) in targetFeatures"
              >
                <v-card
                  class="menu-button"
                  :class="{ active: isIncludesKey(key) }"
                >
                  <v-img class="white--text align-end" :gradient="gradient">
                    <v-card-text class="menu-hazard" v-html="feature.name">
                    </v-card-text>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-switch
              v-model="bulkHazard"
              hide-details
              label="地点ハザード一括検索"
              class="my-2 hazard-switch"
              @change="changeBulkHazard"
            ></v-switch>
            <v-switch
              v-model="visibleShelter"
              hide-details
              label="指定避難所（ズーム13以上）"
              class="my-2 hazard-switch"
              @change="changeVisibleShelter"
            ></v-switch>
            <v-switch
              v-model="visibleShelterEx"
              hide-details
              label="指定緊急避難場所（ズーム13以上）"
              class="my-2 hazard-switch"
              @change="changeVisibleShelterEx"
            ></v-switch>
            <v-divider class="my-1" />
            <v-btn class="px-0" text small @click="getUrl()">
              URLをクリップボードにコピー
            </v-btn>
            <v-divider class="my-2" />
            <div class="d-flex">
              <v-btn class="px-0" text small to="manual" target="_blank">
                ご利用に際して
              </v-btn>
              <v-btn
                color="primary"
                class="ml-auto"
                text
                small
                @click="close()"
              >
                閉じる
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Features } from "../../enums/Feature";
import { Risk } from "../../enums/Risk";
import { BaseTile } from "../../enums/BaseTile";
import { isPrivate } from "../../utils/common";

export default {
  name: "MapMenu",
  data() {
    return {
      BaseTile,
      gradient: "to bottom, rgba(50, 50, 50, 0.1), rgba(50, 50, 50, 0.5)",
      bulkHazard: false,
      visibleShelter: false,
      visibleShelterEx: false
    };
  },
  computed: {
    leafletMap() {
      return this.$store.getters.leafletMap;
    },
    selectedRisk() {
      return this.$store.getters.selectedRisk;
    },
    selectedBaseTile() {
      return this.$store.getters.selectedBaseTile;
    },
    selectedHazardMaps() {
      return this.$store.getters.selectedHazardMaps;
    },
    targetRisk() {
      const keys = Object.keys(Risk).filter(key => {
        if (isPrivate === true) {
          return Risk[key].private === true;
        } else {
          return Risk[key].public === true;
        }
      });
      const res = {};
      keys.forEach(key => {
        res[key] = Risk[key];
      });
      return res;
    },
    targetFeatures() {
      const keys = Object.keys(Features).filter(key => {
        if (isPrivate === true) {
          return Features[key].private === true;
        } else {
          return Features[key].public === true;
        }
      });
      const res = {};
      keys.forEach(key => {
        res[key] = Features[key];
      });
      return res;
    }
  },
  mounted() {
    this.bulkHazard = this.$store.getters.bulkHazard;
    this.visibleShelter = this.$store.getters.visibleShelter;
    this.visibleShelterEx = this.$store.getters.visibleShelterEx;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    isIncludesKey(key) {
      const finded = this.selectedHazardMaps.find(f => f.key === key);
      return finded !== undefined;
    },
    selectHazardMap(key, feature) {
      let selected = [...this.selectedHazardMaps];
      if (this.isIncludesKey(key)) {
        selected = selected.filter(f => f.key !== key);
      } else {
        selected.push({ ...feature });
      }
      this.$store.commit("SET_SELECTED_HAZARD_MAPS", selected);
    },
    selectRisk(riskInfo) {
      if (riskInfo.key === Risk.OBSERVATORY_MONITOR.key) {
        this.$store.commit("SET_IS_SHOW_OBSERVATORY_MONITOR", true);
      } else {
        if (riskInfo.key === this.selectedRisk.key) {
          this.$store.commit("SET_SELECTED_RISK", {});
        } else {
          this.$store.commit("SET_SELECTED_RISK", riskInfo);
        }
      }
      this.close();
    },
    selectBaseTile(baseTileInfo) {
      this.$store.commit("SET_SELECTED_BASE_TILE", baseTileInfo);
      this.close();
    },
    getUrl() {
      const zoom = this.leafletMap.getZoom();
      const origin = location.origin;
      const tile = this.selectedBaseTile.key;
      const risk = !this.selectedRisk.key ? "none" : this.selectedRisk.key;
      const hazardmaps = this.selectedHazardMaps.map(f => f.key);
      const lat = this.leafletMap.getCenter().lat;
      const lng = this.leafletMap.getCenter().lng;
      let url = `${origin}/?openExternalBrowser=1&zoom=${zoom}&risk=${risk}&tile=${tile}&lat=${lat}&lng=${lng}`;
      if (hazardmaps.length > 0) {
        url += `&hazardmaps=${hazardmaps.join(",")}`;
      }
      if (this.bulkHazard === true) {
        url += "&bulkHazard=1";
      }
      if (this.$store.getters.isShowHazardMapControl === true) {
        url += "&hazardLegends=1";
      }
      if (this.$store.getters.isShowUsageGuidePanel === true) {
        url += "&usagePanel=1";
      }
      if (this.$store.getters.visibleShelter === true) {
        url += "&shelter=1";
      }
      if (this.$store.getters.visibleShelterEx === true) {
        url += "&shelterex=1";
      }
      if (
        risk === Risk.OBSERVATORY.key &&
        this.$store.getters.riskSubControl.observatory.length > 0
      ) {
        const obsTypes = this.$store.getters.riskSubControl.observatory.join(
          ","
        );
        url += `&observatory=${obsTypes}`;
      }
      navigator.clipboard.writeText(url);
    },
    changeBulkHazard(bool) {
      this.$store.commit("SET_BULK_HAZARD", bool);
    },
    changeVisibleShelter(bool) {
      this.$store.commit("SET_VISIBLE_SHELTER", bool);
    },
    changeVisibleShelterEx(bool) {
      this.$store.commit("SET_VISIBLE_SHELTER_EX", bool);
    }
  }
};
</script>

<style lang="scss">
.hazard-switch.v-input--switch .v-label {
  font-size: 12px;
}
.menu-button {
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: rgb(122, 162, 236) !important;
  &.active {
    background-color: rgb(47, 94, 182) !important;
  }
  .v-icon {
    padding-right: 3px;
  }
  v-img {
    height: 100%;
  }
  .menu-text {
    padding: 5px;
    font-size: 13px;
  }
  .menu-hazard {
    padding: 3px 5px 3px 10px;
    font-size: 11px;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 310px;
  margin: 0px auto;
  padding: 10px 5px 10px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
