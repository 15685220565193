var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedDateRiskData)?_c('div',[(_vm.selectedDateRiskData.type === 'msm' || _vm.zoomLevel !== 1)?[_vm._l((_vm.slicedImages),function(slicedImage,index){return [(slicedImage.isInDeviceViewPort)?_c('l-marker',{key:index,attrs:{"lat-lng":[slicedImage.markerLat, slicedImage.markerLng]}},[_c('l-icon',{attrs:{"icon-anchor":[0, 0]}},[_c('div',{style:({
              width: slicedImage.divWidth + 'px',
              height: slicedImage.divHeight + 'px',
              overflow: 'hidden',
              'pointer-events': 'none'
            })},[_c('img',{staticClass:"riskImage",style:({
                transform: `translate(0,${slicedImage.translateY}px)`,
                width: slicedImage.imgWidth + 'px',
                height: slicedImage.imgHeight + 'px',
                'pointer-events': 'none',
                opacity: _vm.opacity
              }),attrs:{"alt":"rainfallImage","src":_vm.selectedDateRiskData.image.src}})])])],1):_vm._e()]})]:_c('l-image-overlay',{attrs:{"bounds":_vm.rainImageCoordinate[_vm.selectedDateRiskData.type],"url":_vm.selectedDateRiskData.image.src,"opacity":_vm.opacity}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }