<template>
  <div :style="{ height: '100%', width: '100%' }">
    <div
      :style="{
        height: '100%',
        width: '100%',
        backgroundColor: isGunmaArea() ? 'rgba(0,0,0,0.2)' : 'none'
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "GrayTileLayer",
  props: {
    coords: {
      type: Object,
      required: true
    }
  },
  methods: {
    isGunmaArea() {
      const leftTop = { lat: 37.15967, lng: 137.8131 };
      const rightBottom = { lat: 35.4611, lng: 139.92118 };

      const leftTopCoords = this.getTileCoords(
        leftTop.lat,
        leftTop.lng,
        this.coords.z
      );
      const rightBottomCoords = this.getTileCoords(
        rightBottom.lat,
        rightBottom.lng,
        this.coords.z
      );

      if (
        leftTopCoords.x <= this.coords.x &&
        leftTopCoords.y <= this.coords.y &&
        rightBottomCoords.x >= this.coords.x &&
        rightBottomCoords.y >= this.coords.y
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTileCoords(lat, lon, zoom) {
      const xTile = parseInt(Math.floor(((lon + 180) / 360) * (1 << zoom)));
      const yTile = parseInt(
        Math.floor(
          ((1 -
            Math.log(
              Math.tan((lat * Math.PI) / 180) +
                1 / Math.cos((lat * Math.PI) / 180)
            ) /
              Math.PI) /
            2) *
            (1 << zoom)
        )
      );
      return { x: xTile, y: yTile };
    }
  }
};
</script>

<style scoped></style>
