<template>
  <div class="river-flooding-layer">
    <template v-if="selectedInfo">
      <l-image-overlay
        v-for="(url, i) in selectedInfo.urls"
        :key="i"
        :bounds="riverFloodingInfo.bounds[i]"
        :url="url"
      />
    </template>
  </div>
</template>
<script>
import LImageOverlay from "vue2-leaflet/src/components/LImageOverlay";
import moment from "moment";
import _ from "lodash";
export default {
  name: "RiverFloodingLayer",
  components: { LImageOverlay },
  inject: {
    baseMapData: {
      default: []
    }
  },
  computed: {
    riverFloodingInfo() {
      return this.$store.getters.riverFloodingInfo;
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedInfo() {
      if (!this.selectedDate) {
        return;
      }
      if (!this.selectedDate || !this.riverFloodingInfo) {
        return;
      }
      return _.find(this.riverFloodingInfo.imageInfos, o => {
        return this.selectedDate.isSame(moment.utc(o.date, "YYYY/MM/DD HH:mm"));
      });
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    baseDate() {
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("FETCH_RIVER_FLOODING");
    }
  }
};
</script>

<style scoped></style>
