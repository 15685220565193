<template>
  <div>
    <template v-if="zoom > 8">
      <template v-for="(marker, key) in observatoryMarkers">
        <ObservatoryMarker
          :observatories="observatories"
          :observatoryType="marker.type"
          :zIndex="marker.zIndex"
          :key="key"
        />
      </template>
    </template>
    <v-dialog v-model="isShowDialog" persistent scrollable max-width="400">
      <v-card max-height="60vh">
        <v-card-text class="ma-0 px-2 py-0">
          <v-list dense>
            <template v-for="(obs, i) in draftObservatories">
              <v-list-item :key="i" link @click="selectDraftObservatory(obs)">
                <v-list-item-icon>
                  <v-icon small>{{ obs.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="obs.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="`d-${i}`"></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowDialog = false">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { OBSERVATORY } from "@/enums/Type";
import L from "leaflet";
import "leaflet-markers-canvas";
import ObservatoryMarker from "./ObservatoryMarker";

export default {
  name: "ObservatoryLayer",
  components: {
    ObservatoryMarker
  },
  data() {
    return {
      isClicked: false,
      draftObservatories: [],
      isShowDialog: false,
      tooltipLayer: null,
      observatoryMarkers: [
        { type: OBSERVATORY.STAGE.code, zIndex: 640 },
        { type: OBSERVATORY.DAM.code, zIndex: 630 },
        { type: OBSERVATORY.RAIN.code, zIndex: 620 },
        { type: OBSERVATORY.CAMERA.code, zIndex: 610 },
        { type: OBSERVATORY.LIVECAMERA.code, zIndex: 600 }
      ]
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return Math.floor(this.map.getZoom());
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    riskSubControl() {
      return this.$store.getters.riskSubControl;
    },
    observatories() {
      const observatories = this.$store.getters.observatories;
      const filteredType = this.riskSubControl.observatory;
      const waterKinds = this.riskSubControl.waterKinds;
      const targets = observatories.filter(
        o =>
          filteredType.includes(o.type) &&
          o.lat !== undefined &&
          o.lng !== undefined
      );
      if (waterKinds) {
        return targets.filter(
          o =>
            o.type !== OBSERVATORY.STAGE.code ||
            (o.type === OBSERVATORY.STAGE.code && waterKinds.includes(o.kind))
        );
      } else {
        return targets;
      }
    }
  },
  watch: {
    baseDate() {
      this.fetch();
    }
  },
  created() {
    this.fetch();
    this.tooltipLayer = L.layerGroup();
    this.tooltipLayer.id = "tooltiplayer";
    this.map.addLayer(this.tooltipLayer);
  },
  mounted() {
    if (this.map) {
      this.map.on("moveend", this.drawTooltips);
      this.map.on("click", this.clickMapMarkers);
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off("moveend", this.drawTooltips);
      this.map.off("click", this.clickMapMarkers);
      if (this.tooltipLayer !== null) {
        this.tooltipLayer.clearLayers();
        this.tooltipLayer.remove();
      }
    }
  },
  methods: {
    fetch() {
      this.$store.dispatch("FETCH_OBSERVATORIES");
    },
    hasStageObservatory(observatoryId) {
      const finded = this.observatories.find(
        o =>
          o._id === observatoryId &&
          o.type === OBSERVATORY.STAGE.code &&
          o.lat !== undefined &&
          o.lng !== undefined
      );
      return finded !== undefined;
    },
    drawTooltips() {
      this.tooltipLayer.clearLayers();
      if (this.zoom < 14) {
        return;
      }
      const bounds = this.map.getBounds();
      this.observatories.forEach(obs => {
        if (obs.type === OBSERVATORY.CAMERA.code) {
          if (this.hasStageObservatory(obs._id) === true) {
            return;
          }
        }
        if (bounds.contains([obs.lat, obs.lng]) !== true) {
          return;
        }
        const tooltip = L.tooltip({
          direction: "top",
          offset: [0, -15],
          permanent: true
        });
        tooltip.setContent(obs.name);
        tooltip.setLatLng([obs.lat, obs.lng]);
        tooltip.addTo(this.tooltipLayer);
      });
    },
    clickMapMarkers(event) {
      // 二重起動防止
      if (this.isClicked) {
        return;
      }
      this.isClicked = true;
      setTimeout(() => {
        this.isClicked = false;
      }, 500);
      const p = this.map.latLngToLayerPoint(event.latlng);
      const southWest = this.map.layerPointToLatLng([p.x - 25, p.y + 25]);
      const northEast = this.map.layerPointToLatLng([p.x + 25, p.y - 25]);
      const bounds = new L.latLngBounds(southWest, northEast);
      const targets = this.observatories.filter(o =>
        bounds.contains(L.latLng(o.lat, o.lng))
      );
      if (targets.length === 0) {
        return;
      }
      if (targets.length === 1) {
        this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", targets[0]);
        return;
      }
      if (targets.length === 2) {
        const types = [OBSERVATORY.STAGE.code, OBSERVATORY.CAMERA.code];
        if (
          targets[0]._id === targets[1]._id &&
          types.includes(targets[0].type) &&
          types.includes(targets[1].type)
        ) {
          const finded = targets.find(t => t.type === OBSERVATORY.STAGE.code);
          if (finded !== undefined) {
            this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", finded);
            return;
          }
        }
      }
      this.draftObservatories = targets.concat();
      this.draftObservatories.forEach(obs => {
        switch (obs.type) {
          case OBSERVATORY.RAIN.code:
            obs.icon = "mdi-checkbox-blank-circle";
            break;
          case OBSERVATORY.STAGE.code:
            obs.icon = "mdi-triangle";
            break;
          case OBSERVATORY.CAMERA.code:
            obs.icon = "mdi-camera";
            break;
          case OBSERVATORY.LIVECAMERA.code:
            obs.icon = "mdi-camera-iris";
            break;
          case OBSERVATORY.DAM.code:
            obs.icon = "mdi-car-windshield";
            break;
        }
      });
      setTimeout(() => {
        this.isShowDialog = true;
      }, 300);
    },
    selectDraftObservatory(observatoryInfo) {
      this.$store.dispatch("FETCH_SELECTED_OBSERVATORY_DATA", observatoryInfo);
      this.isShowDialog = false;
    }
  }
};
</script>
