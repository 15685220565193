<template>
  <div v-if="selectedDateRiskData">
    <template v-if="selectedDateRiskData.type === 'msm' || zoomLevel !== 1">
      <template v-for="(slicedImage, index) in slicedImages">
        <l-marker
          :key="index"
          :lat-lng="[slicedImage.markerLat, slicedImage.markerLng]"
          v-if="slicedImage.isInDeviceViewPort"
        >
          <l-icon :icon-anchor="[0, 0]">
            <div
              :style="{
                width: slicedImage.divWidth + 'px',
                height: slicedImage.divHeight + 'px',
                overflow: 'hidden',
                'pointer-events': 'none'
              }"
            >
              <img
                class="riskImage"
                alt="rainfallImage"
                :src="selectedDateRiskData.image.src"
                :style="{
                  transform: `translate(0,${slicedImage.translateY}px)`,
                  width: slicedImage.imgWidth + 'px',
                  height: slicedImage.imgHeight + 'px',
                  'pointer-events': 'none',
                  opacity: opacity
                }"
              />
            </div>
          </l-icon>
        </l-marker>
      </template>
    </template>
    <l-image-overlay
      v-else
      :bounds="rainImageCoordinate[selectedDateRiskData.type]"
      :url="selectedDateRiskData.image.src"
      :opacity="opacity"
    >
    </l-image-overlay>
  </div>
</template>
<script>
import { LImageOverlay, LMarker, LIcon } from "vue2-leaflet";
import _ from "lodash";
import moment from "moment";

export default {
  name: "RainLayer",
  components: {
    "l-marker": LMarker,
    "l-icon": LIcon,
    LImageOverlay
  },
  data() {
    return {
      zoomLevel: 1, // zoomを３つステップに分けている。zoomが>= 9の場合：1、>= 7 && < 9の場合：2、その他の場合：3
      viewportBottomLat: 0,
      viewportTopLat: 0
    };
  },
  computed: {
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedDateRiskData() {
      if (!this.selectedDate) {
        return;
      }
      return _.find(this.rainImageInfo, o => {
        return this.selectedDate.isSame(moment.utc(o.date, "YYYY/MM/DD HH:mm"));
      });
    },
    map() {
      return this.$store.getters.leafletMap;
    },
    rainImageCoordinate() {
      return this.$store.getters.rainImageCoordinate;
    },
    rainImageInfo() {
      return this.$store.getters.rainImageInfo;
    },
    imageSizeInfo() {
      return this.$store.getters.imageSizeInfo;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    },
    riskSubControl() {
      return this.$store.getters.riskSubControl;
    },
    opacity() {
      return this.riskSubControl.rain;
    },
    slicedImages() {
      if (!this.map) {
        return [];
      }

      const imagesInfo = [];
      const bounds = this.rainImageCoordinate[this.selectedDateRiskData.type];
      const coordinate = {
        north: bounds[0][0],
        west: bounds[0][1],
        south: bounds[1][0],
        east: bounds[1][1]
      };

      const height = this.imageSizeInfo[this.selectedDateRiskData.type].height;
      let sliceCount;
      switch (height) {
        case 1300:
          sliceCount = 50;
          break;
        case 550:
          sliceCount = 50;
          break;
        case 505:
          sliceCount = 50;
          break;
        case 1248:
          sliceCount = 48;
          break;
        case 672:
          sliceCount = 48;
          break;
        default:
          sliceCount = 50;
          break;
      }

      const slicedLatHeight =
        (coordinate.north - coordinate.south) / sliceCount;

      for (let i = 0; i < sliceCount; i++) {
        const slicedImageBottomLeftPoint = this.map.project([
          coordinate.north - i * slicedLatHeight - slicedLatHeight,
          coordinate.west
        ]);
        const slicedImageTopRightPoint = this.map.project([
          coordinate.north - i * slicedLatHeight,
          coordinate.east
        ]);

        let slicedImageHeight =
          slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
        let slicedImageWidth =
          slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;

        if (
          Math.round(slicedImageBottomLeftPoint.y) <
          Math.round(
            slicedImageBottomLeftPoint.y + Math.ceil(slicedImageHeight)
          )
        ) {
          slicedImageHeight -= -1;
        }

        const markerLat = coordinate.north - i * slicedLatHeight;
        const markerLng = coordinate.west;
        const divHeight = Math.ceil(slicedImageHeight) - 1.4;

        imagesInfo.push({
          markerLat: markerLat,
          markerLng: markerLng,
          divWidth: slicedImageWidth,
          divHeight: divHeight,
          imgWidth: slicedImageWidth,
          imgHeight: slicedImageHeight * sliceCount,
          translateY: -(i * slicedImageHeight + 1),
          isInDeviceViewPort: this.isElementInDeviceViewPort(
            markerLat,
            markerLng,
            divHeight
          )
        });
      }

      return imagesInfo;
    }
  },
  watch: {
    baseDate() {
      this.loadData();
    }
  },
  created() {
    this.$store.commit("SET_IS_LOADING", true);
    this.loadData();
  },
  mounted() {
    this.addMapEventListeners();
    this.updateViewportLatitude();
    setTimeout(() => {
      this.$store.commit("SET_IS_LOADING", false);
    }, 3000);
  },
  beforeDestroy() {
    this.removeMapEventListeners();
  },
  methods: {
    loadData() {
      this.zoomLevel = this.getZoomLevel();
      this.$store.dispatch("FETCH_RAIN", { level: this.zoomLevel });
    },
    addMapEventListeners() {
      this.map.on("zoomend", this.loadRainImageByZoomLevel);
      this.map.on("moveend", this.updateViewportLatitude);
    },
    removeMapEventListeners() {
      if (this.map) {
        this.map.off("zoomend", this.loadRainImageByZoomLevel);
        this.map.off("moveend", this.updateViewportLatitude);
      }
    },
    getZoomLevel() {
      const zoom = this.map.getZoom();
      let level;
      if (zoom >= 9) {
        level = 1;
      } else if (zoom >= 7 && zoom < 9) {
        level = 2;
      } else {
        level = 3;
      }
      return level;
    },
    loadRainImageByZoomLevel() {
      if (this.getZoomLevel() !== this.zoomLevel) {
        this.loadData();
      }

      // 表示中画面の枠変わったので最新緯度値を取得する
      this.updateViewportLatitude();
    },

    /**
     * 現在表示中画面（viewport）の最新緯度を取得する
     * @return {void}
     */

    updateViewportLatitude() {
      this.viewportBottomLat = this.getScreenBottomLat();
      this.viewportTopLat = this.getScreenTopLat();
    },

    /**
     * 現在表示中画面（viewport）の下の緯度取得
     * @return {latitude}
     */

    getScreenBottomLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMinY = [pointC.x, pointC.y - window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMinY);
      return latlng.lat;
    },

    /**
     * 現在表示中画面（viewport）の上のの緯度取得
     * @return {latitude}
     */
    getScreenTopLat() {
      let centerLatLng = this.map.getCenter(); // get map center
      let pointC = this.map.latLngToContainerPoint(centerLatLng); // convert to containerpoint (pixels)
      let pointMaxY = [pointC.x, pointC.y + window.innerHeight / 2];
      let latlng = this.map.containerPointToLatLng(pointMaxY);
      return latlng.lat;
    },

    /**
     * DOMエレメントが現在デバイス画面（viewport）内で表示されているか確認する
     * @param {latitude} elementBottomLat DOMエレメントの緯度
     * @param {longitude} elementBottomLng DOMエレメントの軽度
     * @param {float} elementHeightPx DOMエレメントの高さ（px）
     * @return {bool} true: デバイス画面内に表示されている、false: 画面外表示されている
     */
    isElementInDeviceViewPort(
      elementBottomLat,
      elementBottomLng,
      elementHeightPx
    ) {
      const elementTopLat = this.getElemenTopLat(
        [elementBottomLat, elementBottomLng],
        elementHeightPx
      );

      if (
        elementTopLat < this.viewportBottomLat &&
        elementTopLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat < this.viewportBottomLat &&
        elementBottomLat > this.viewportTopLat
      ) {
        return true;
      }
      if (
        elementBottomLat >= this.viewportBottomLat &&
        elementTopLat <= this.viewportTopLat
      ) {
        return true;
      }

      return false;
    },

    /**
     * エレメントの上のの緯度取得
     * @param {Array<float>} elementTopLatLng
     * @param {float} divHeight
     * @return {latitude}
     */

    getElemenTopLat(elementTopLatLng, elementHeightPx) {
      let pointDivTop = this.map.latLngToContainerPoint(elementTopLatLng); // convert to containerpoint (pixels)
      let pointY = [pointDivTop.x, pointDivTop.y + elementHeightPx];

      let latlng = this.map.containerPointToLatLng(pointY);
      return latlng.lat;
    }
  }
};
</script>
