<template>
  <div></div>
</template>
<script>
import { OBSERVATORY } from "@/enums/Type";
import L from "leaflet";
import "leaflet-markers-canvas";

export default {
  name: "ObservatoryMarker",
  data() {
    return {
      markers: [],
      layer: null
    };
  },
  props: {
    observatories: {
      type: [Array, Object],
      required: true
    },
    observatoryType: {
      type: Number,
      required: true
    },
    zIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return Math.floor(this.map.getZoom());
    },
    paneName() {
      return `observatory-marker-pane${this.observatoryType}`;
    },
    layerName() {
      return `observatory-marker-layer${this.observatoryType}`;
    },
    isStage() {
      return this.observatoryType === OBSERVATORY.STAGE.code;
    },
    isRain() {
      return this.observatoryType === OBSERVATORY.RAIN.code;
    },
    rainInterval() {
      return this.$store.getters.rainInterval;
    },
    iconSize() {
      return 24;
    },
    observatoriesByType() {
      return this.observatories.filter(o => o.type === this.observatoryType);
    }
  },
  watch: {
    observatories(newval, oldval) {
      if (newval !== oldval) {
        this.load();
      }
    },
    rainInterval(newval, oldval) {
      if (newval !== oldval) {
        this.load();
      }
    },
    zoom(newval, oldval) {
      if (Math.floor(newval) !== Math.floor(oldval)) {
        this.load();
      }
    }
  },
  created() {
    if (!this.map) {
      return;
    }
    if (this.map.getPane(this.paneName) === undefined) {
      this.map.createPane(this.paneName).style.zIndex = this.zIndex;
    }
    this.layer = new L.MarkersCanvas({ pane: this.paneName });
    this.layer.id = this.layerName;
    this.layer.addTo(this.map);
    this.load();
  },
  mounted() {
    // this.load();
  },
  beforeDestroy() {
    if (!this.map) {
      return;
    }
    this.clear();
    this.map.eachLayer(layer => {
      if (layer.id === this.layerName) {
        this.map.removeLayer(layer);
      }
    });
  },
  methods: {
    clear() {
      if (this.layer !== null) {
        try {
          this.layer.removeMarkers(this.markers);
        } catch (e) {
          //
        }
        this.markers = [];
      }
    },
    redraw() {
      this.layer.redraw();
      this.map.panTo(this.map.getCenter());
    },
    load() {
      this.clear();
      if (!this.layer) {
        return;
      }
      if (this.observatoriesByType.length === 0) {
        this.redraw();
        return;
      }
      this.observatoriesByType.forEach(obs => {
        const iconFileName = this.getIconFilename(obs);
        const iconUrl = require(`@/assets/images/observatory-icons/${iconFileName}`);
        const iconAnchor = this.getIconAnchor(obs);
        const icon = L.icon({
          iconSize: [this.iconSize, this.iconSize],
          iconAnchor: iconAnchor,
          iconUrl: iconUrl
        });
        const latLng = L.latLng([obs.lat, obs.lng]);
        const marker = L.marker(latLng, {
          icon: icon
        });
        this.markers.push(marker);
      });
      this.layer.addMarkers(this.markers);
      this.redraw();
    },
    getNearOffset(obs, obsType) {
      const finded = obs.near.find(n => n.type === obsType);
      if (finded === undefined) {
        return 0;
      }
      const distance = finded.distance;
      const target = this.observatories.find(
        o => o.type === obsType && o._id === finded._id
      );
      if (target === undefined) {
        return 0;
      }
      if (this.zoom >= 16 && distance > 0) {
        return 0;
      }
      if (this.zoom >= 13 && distance >= 100) {
        return 0;
      }
      const rate = (200 - distance) / 200;
      return Math.floor(this.iconSize * rate * 0.8);
    },
    getIconAnchor(obs) {
      const anchor = [this.iconSize * 0.5, this.iconSize * 0.5];
      if (this.zoom < 12 || obs.near === undefined) {
        return anchor;
      }
      // カメラ 水位の近くなら上へ表示
      if (obs.type === OBSERVATORY.CAMERA.code) {
        const offset = this.getNearOffset(obs, OBSERVATORY.STAGE.code);
        anchor[1] += offset;
      }
      // ライブカメラ カメラの近くなら下へ表示
      if (obs.type === OBSERVATORY.LIVECAMERA.code) {
        const offset = this.getNearOffset(obs, OBSERVATORY.CAMERA.code);
        anchor[1] -= offset;
      }
      return anchor;
    },
    getIconFilename(obs) {
      switch (obs.type) {
        case OBSERVATORY.STAGE.code:
          switch (obs.status) {
            case "normal":
              return "stage-normal.png";
            case "start":
              return "stage-start.png";
            case "standby":
              return "stage-standby.png";
            case "warning":
              return "stage-warning.png";
            case "evacuation":
              return "stage-evacuation.png";
            case "dangerous":
              return "stage-dangerous.png";
            case "outbreak":
              return "stage-outbreak.png";
            default:
              return "stage-deficit.png";
          }
        case OBSERVATORY.RAIN.code: {
          let val = -1;
          if (obs.status !== undefined) {
            if (obs.status[this.rainInterval] !== undefined) {
              val = obs.status[this.rainInterval];
            }
          }
          const isMin10 = this.rainInterval === "min10";
          if ((isMin10 && val >= 20) || (!isMin10 && val >= 80)) {
            return "rain-5.png";
          } else if ((isMin10 && val >= 10) || (!isMin10 && val >= 50)) {
            return "rain-4.png";
          } else if ((isMin10 && val >= 5) || (!isMin10 && val >= 30)) {
            return "rain-3.png";
          } else if ((isMin10 && val >= 1) || (!isMin10 && val >= 20)) {
            return "rain-2.png";
          } else if (!isMin10 && val >= 1) {
            return "rain-1.png";
          } else if (val >= 0) {
            return "rain-0.png";
          } else {
            return "rain-deficit.png";
          }
        }
        case OBSERVATORY.DAM.code:
          switch (obs.status) {
            case "deficit":
              return "dam-deficit.png";
            case "normal":
              return "dam-normal.png";
            default:
              return "dam-default.png";
          }
        case OBSERVATORY.CAMERA.code:
          switch (obs.status) {
            case "deficit":
              return "camera-deficit.png";
            case "normal":
              return "camera-normal.png";
            default:
              return "camera-default.png";
          }
        case OBSERVATORY.LIVECAMERA.code:
          return "camera-iris.png";
      }
    }
  }
};
</script>

<style>
.leaflet-marker-icon {
  image-rendering: inherit;
}
</style>
