<template>
  <div class="date-time-select-area d-flex flex-row-reverse">
    <div class="fab-update-button ml-1" @click="updateNow()">
      <v-icon size="22">mdi-update</v-icon>
    </div>
    <datetime
      v-model="baseDate"
      input-class="datetime-picker"
      type="datetime"
      :max-datetime="currentDate"
      format="MM月dd日 HH:mm"
    >
      <template slot="button-cancel">
        <v-btn text color="error">
          キャンセル
        </v-btn>
      </template>
      <template slot="button-confirm" slot-scope="scope">
        <span v-if="scope.step === 'date'">
          <v-btn icon color="indigo">
            <v-icon>mdi-clock</v-icon>
          </v-btn>
        </span>
        <span v-else>
          <v-btn text color="success">
            確定
          </v-btn>
        </span>
      </template>
    </datetime>
  </div>
</template>

<script>
import moment from "moment";
import { Datetime } from "vue-datetime";
import Vue from "vue";
Vue.component("datetime", Datetime);

export default {
  name: "BasetimeControl",
  components: {
    datetime: Datetime
  },
  data() {
    return {
      currentDate: moment(new Date()).toISOString()
    };
  },
  computed: {
    baseDate: {
      get() {
        return this.$store.getters.baseDate.toISOString();
      },
      set(date) {
        this.$store.commit("SET_BASE_DATE", moment.utc(date));
      }
    }
  },
  methods: {
    updateNow() {
      this.$store.commit("SET_BASE_DATE", moment().utc());
    }
  }
};
</script>

<style lang="scss">
@import "../../common";
.datetime-picker {
  cursor: pointer;
  border: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  background-color: $point-color-1;
  color: $base-color-1;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 01 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  min-height: 26px;
  height: 28px;
  width: 110px;
  text-align: center;
}
</style>

<style lang="scss" scoped>
.date-time-select-area {
  pointer-events: painted !important;
}
.fab-update-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: rgb(255, 190, 61);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
}
</style>
