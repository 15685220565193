const OBSERVATORY = {
  RAIN: {
    code: 1,
    name: "雨量"
  },
  STAGE: {
    code: 4,
    name: "水位"
  },
  DAM: {
    code: 7,
    name: "ダム"
  },
  CAMERA: {
    code: 30,
    name: "カメラ"
  },
  LIVECAMERA: {
    code: 50,
    name: "ライブカメラ"
  }
};

const STAGES = {
  OUTBREAK: {
    label: "氾濫発生水位",
    color: "#000000"
  },
  DANGEROUS: {
    label: "氾濫危険水位",
    color: "#ad02ad"
  },
  EVACUATION: {
    label: "避難判断水位",
    color: "#ff2800"
  },
  WARNING: {
    label: "氾濫注意水位",
    color: "#f2e700"
  },
  STANDBY: {
    label: "水防団待機水位",
    color: "#008000"
  },
  START: {
    label: "観測開始水位",
    color: "#22b022"
  },
  NORMAL: {
    label: "平常",
    color: "#ffffff"
  },
  DEFICIT: {
    label: "休止・欠測等",
    color: "#aaaaaa"
  }
};

const FLOODING = {
  NAISUI: {
    code: 1,
    name: "内水"
  },
  GAISUI: {
    code: 2,
    name: "外水"
  }
};

export { OBSERVATORY, STAGES, FLOODING };
