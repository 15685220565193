<!-- ログイン画面 -->
<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-card class="elevation-10" width="310">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title style="font-size: 1em"
            >群馬県リアルタイム水害リスク情報</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              placeholder="ユーザー名"
              prepend-icon="mdi-account"
              type="text"
              :rules="[rules.required]"
              v-model="username"
            ></v-text-field>
            <v-text-field
              id="password"
              placeholder="パスワード"
              prepend-icon="mdi-lock"
              type="password"
              :rules="[rules.required]"
              v-model="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text v-if="errorMessage">
          <div class="error-message">{{ errorMessage }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="login">ログイン</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import * as axiosHelper from "../utils/axiosHelper";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      rules: {
        required: value => !!value || "必須です。"
      }
    };
  },
  methods: {
    async login() {
      this.errorMessage = "";

      if (!this.$refs.form.validate()) {
        this.errorMessage = "バリデーションエラー";
        return;
      }

      const params = {
        userId: this.username,
        userPassWord: this.password,
        access_key: "gnm_pref_access_token_key"
      };

      const response = await axiosHelper.get("/login", params);
      if (response === null || response.token === undefined) {
        this.errorMessage = "ユーザ名もしくパスワードが間違っています。";
      } else {
        this.$cookies.set("access_token", response.token);
        this.$router.replace("/");
      }
    }
  }
};
</script>
<style scoped>
.error-message {
  color: red;
  text-align: center;
}
</style>
