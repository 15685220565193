import Vue from "vue";
import VueRouter from "vue-router";
import Mobile from "../views/Mobile";
import { isPrivate } from "../utils/common";
import ManualPublic from "../views/ManualPublic";
import ManualPrivate from "../views/ManualPrivate";
import Observatories from "../views/Observatories";
import Login from "../views/Login";
import * as axiosHelper from "../utils/axiosHelper";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Mobile",
    component: Mobile
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/manual",
    component: isPrivate === true ? ManualPrivate : ManualPublic
  },
  {
    path: "/observatories",
    component: Observatories
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  if (to.path === "/login" && isPrivate !== true) {
    next("/");
    return;
  }
  if (isPrivate !== true) {
    next();
    return;
  }
  // 以下内部向けのみの処理
  if (to.path === "/login") {
    next();
    return;
  }
  if (Vue.$cookies.isKey("access_token") && Vue.$cookies.get("access_token")) {
    const response = await axiosHelper.get("/session/check", {});
    if (response !== null && response.session === true) {
      next();
      return;
    }
  }
  next("/login");
});

export default router;
