<template>
  <div class="live-camera-container pa-3">
    <div class="mb-3">
      <h4>{{ selectedObservatory.name }}</h4>
      <div class="caption text--secondary">
        {{ selectedObservatory.address }}
      </div>
      <div class="caption text--secondary">
        {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
      </div>
    </div>
    <div class="pa-2">
      <v-card>
        <div class="video-container">
          <iframe
            width="560"
            height="315"
            :src="
              `https://www.youtube.com/embed/${selectedObservatory.youtube}?autoplay=1`
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "LiveCameraDetail",
  data() {
    return {
      moment,
      currentImageIndex: 0
    };
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    }
  }
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
