export const Risk = {
  RAIN: {
    index: 0,
    key: "rain",
    string: "予測降雨",
    title: "予測降雨",
    icon: "mdi-umbrella",
    slider: true,
    subControl: true,
    public: true,
    private: true
  },
  OBSERVATORY: {
    index: 1,
    key: "observatory",
    string: "観測所",
    title: "観測所",
    icon: "mdi-office-building-marker",
    subControl: true,
    public: true,
    private: true
  },
  KOUZUI: {
    index: 2,
    key: "kouzui",
    string: "洪水キキクル",
    title: "洪水キキクル",
    icon: "mdi-alert",
    slider: true,
    subControl: false,
    public: true,
    private: true
  },
  DOSYA: {
    index: 3,
    key: "dosya",
    string: "土砂キキクル",
    title: "土砂キキクル",
    icon: "mdi-elevation-decline",
    slider: true,
    subControl: true,
    public: true,
    private: true
  },
  RIVER_FLOODING: {
    index: 4,
    key: "riverFlooding",
    string: "河川・内水氾濫",
    title: "河川・内水氾濫",
    icon: "mdi-wave",
    slider: true,
    subControl: true,
    public: false,
    private: true
  },
  OBSERVATORY_MONITOR: {
    index: 5,
    key: "observatoryMonitor",
    string: "水位/雨量一覧",
    title: "水位/雨量一覧",
    icon: "mdi-eye",
    slider: false,
    subControl: false,
    public: false,
    private: true
  }
};
