<template>
  <div class="tiles" v-if="selectedHazardMaps.length > 0">
    <template v-for="(feature, idx1) in selectedHazardMaps">
      <l-tile-layer
        v-for="(url, idx2) in feature.urls"
        layer-type="overlay"
        class="tiles"
        :key="`${idx1}-${idx2}`"
        :url="url"
        :opacity="feature.key === 'under_path' ? 0.8 : opacity"
      ></l-tile-layer>
    </template>
  </div>
</template>

<script>
import { LTileLayer } from "vue2-leaflet";

export default {
  name: "HazardMapLayer",
  components: { LTileLayer },
  computed: {
    selectedHazardMaps() {
      return this.$store.getters.selectedHazardMaps;
    },
    opacity() {
      return this.$store.getters.riskSubControl.hazard;
    }
  }
};
</script>

<style scoped>
.tiles {
  z-index: 5000;
}
</style>
