<template>
  <div class="dam-level-detail pa-3">
    <div class="mb-3">
      <div class="caption text--secondary">
        {{ selectedObservatory.address }}
      </div>
      <div class="caption text--secondary">
        {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
      </div>
    </div>
    <div class="mt-5 caption" v-if="mesuredData.length === 0">
      この観測所は欠測しています...
    </div>
    <DamChart
      v-if="mesuredData.length > 0"
      :height="250"
      :selected-observatory="selectedObservatory"
      :baselines="baselines"
    />
    <div class="mt-3">
      <div class="caption">氾濫水位</div>
      <v-row
        dense
        no-gutters
        class="body-2"
        v-for="line in baselines"
        :key="line.key"
      >
        <v-col cols="1" />
        <v-col cols="1">
          <div
            class="baseline-color"
            :style="{ 'border-bottom-color': line.color }"
          ></div>
        </v-col>
        <v-col cols="3" class="caption">{{ line.name }}</v-col>
        <v-col cols="3" class="caption text-right">
          {{ line.level + " m" }}
        </v-col>
        <v-spacer />
      </v-row>
    </div>
    <v-simple-table dense class="mt-3">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-left">貯水位</th>
            <th class="text-left">全流入量</th>
            <th class="text-left">全放流量</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ts in timeSeries"
            :key="ts.time"
            :class="{ predict: ts.predict }"
          >
            <td>{{ ts.time }}</td>
            <td>{{ ts.level }}</td>
            <td>{{ ts.qinAll }}</td>
            <td>{{ ts.qoutAll }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import DamChart from "./DamChart";
import moment from "moment";

export default {
  name: "DamDetail",
  components: { DamChart },
  computed: {
    baseDate() {
      return this.$store.getters.baseDate;
    },
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    baselines() {
      if (!this.selectedObservatory.baselines) {
        return [];
      }
      const current = this.baseDate.format("MM/DD");
      const baselines = [];
      this.selectedObservatory.baselines.forEach(line => {
        if (line.detals === undefined || line.detals.length === 0) {
          return;
        }
        const finded = line.detals.find(d => {
          const start = moment.utc(d.start, "M/D").format("MM/DD");
          const end = moment.utc(d.end, "M/D").format("MM/DD");
          return start <= current && end >= current;
        });
        if (finded === undefined) {
          return;
        }
        baselines.push({
          key: line.key,
          name: line.name,
          color: line.color,
          level: finded.value
        });
      });
      return baselines;
    },
    mesuredData() {
      if (this.selectedObservatory.waterVolumes === undefined) {
        return [];
      }
      return this.selectedObservatory.waterVolumes.filter(
        v => v.type === "real"
      );
    },
    predictData() {
      if (this.selectedObservatory.waterVolumes === undefined) {
        return [];
      }
      return this.selectedObservatory.waterVolumes.filter(
        v => v.type === "forecast"
      );
    },
    timeSeries() {
      const timeSeries = [];
      this.mesuredData.forEach(r => {
        r["predict"] = false;
        timeSeries.push(r);
      });
      this.predictData.forEach(r => {
        r["predict"] = true;
        timeSeries.push(r);
      });
      return timeSeries.map(r => {
        const time = moment
          .utc(r.dateTime, "YYYY/MM/DD HH:mm")
          .local()
          .format("MM/DD HH:mm");
        return {
          time: time,
          level: r.level !== undefined ? parseFloat(r.level).toFixed(2) : "---",
          qinAll:
            r.qinAll !== undefined ? parseFloat(r.qinAll).toFixed(2) : "---",
          qoutAll:
            r.qoutAll !== undefined ? parseFloat(r.qoutAll).toFixed(2) : "---",
          predict: r.predict
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dam-level-detail {
  thead th {
    background-color: #addff3;
    font-size: 0.6em !important;
    font-weight: normal;
    white-space: nowrap;
  }
  tbody td {
    background: #eee;
    font-size: 0.8em !important;
    white-space: nowrap;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .predict {
    color: blue;
  }
  .baseline-color {
    width: 16px;
    height: 12px;
    border-bottom: white 3px solid;
  }
}
</style>
