<template>
  <v-dialog v-model="isShowObservatoryMonitor" persistent>
    <v-card class="pa-2">
      <div class="d-flex flex-row-reverse mb-1">
        <v-btn icon x-small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="observatory-monitor-header d-flex mb-2">
        <v-select
          v-model="mode"
          :items="modeItems"
          item-text="text"
          item-value="id"
          solo
          dense
          hide-details
          class="caption mr-2"
          style="max-width: 130px"
          @change="changeMode()"
        ></v-select>
        <v-select
          v-if="mode === 'city'"
          v-model="cityId"
          :items="cities"
          item-text="name"
          item-value="_id"
          solo
          dense
          hide-details
          class="caption"
          style="max-width: 130px"
          @change="fetch()"
        ></v-select>
        <v-select
          v-if="mode === 'office'"
          v-model="officeId"
          :items="offices"
          item-text="name"
          item-value="_id"
          solo
          dense
          hide-details
          class="caption"
          style="max-width: 170px"
          @change="fetch()"
        ></v-select>
      </div>

      <div class="observatory-monitor-contents">
        <v-card class="d-flex align-center" flat tile height="30">
          <v-chip small>
            <v-switch dense v-model="visiblity.stage"></v-switch>
            水位モニタリング
          </v-chip>
        </v-card>
        <div v-if="visiblity.stage">
          <v-data-table
            dense
            :headers="stageHeaders"
            :items="stages"
            hide-default-footer
            fixed-header
            :items-per-page="-1"
            mobile-breakpoint="0"
            @click:row="moveObservatoryDetail"
          >
            <template v-slot:[`item.level`]="{ item }">
              <v-chip
                class="chip-val px-2"
                :color="item.color"
                :dark="item.color !== 'transparent'"
              >
                {{ item.level }}
              </v-chip>
            </template>
          </v-data-table>
          <div class="d-flex caption flex-row-reverse">
            (単位: m)
          </div>
        </div>

        <v-card class="d-flex align-center" flat tile height="30">
          <v-chip small>
            <v-switch dense v-model="visiblity.rain"></v-switch>
            雨量モニタリング
          </v-chip>
        </v-card>
        <div v-if="visiblity.rain">
          <v-data-table
            dense
            :headers="rainHeaders"
            :items="rains"
            hide-default-footer
            :items-per-page="-1"
            mobile-breakpoint="0"
            @click:row="moveObservatoryDetail"
          >
            <template v-slot:[`item.min10`]="{ item }">
              <v-chip
                class="chip-val"
                :color="item.min10Color"
                :dark="item.min10Color !== 'transparent'"
              >
                {{ item.min10 }}
              </v-chip>
            </template>
            <template v-slot:[`item.min60`]="{ item }">
              <v-chip
                class="chip-val"
                :color="item.min60Color"
                :dark="item.min60Color !== 'transparent'"
              >
                {{ item.min60 }}
              </v-chip>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <v-chip
                class="chip-val"
                :color="item.totalColor"
                :dark="item.totalColor !== 'transparent'"
              >
                {{ item.total }}
              </v-chip>
            </template>
          </v-data-table>
          <div class="d-flex caption flex-row-reverse">
            (10分: mm/10分, 時間: mm/h, 累計: mm)
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { STAGES } from "../enums/Type";
import { isPrivate } from "../utils/common";
import * as axiosHelper from "../utils/axiosHelper";

export default {
  name: "ObservatoryMonitor",
  data() {
    return {
      modeItems: [
        { id: "city", text: "市町村" },
        { id: "office", text: "土木事務所" }
      ],
      mode: "city",
      tab: 4,
      stages: [],
      rains: [],
      visiblity: {
        stage: true,
        rain: true
      },
      cityId: 0,
      officeId: 0,
      storageKey: "monitor",
      stageHeaders: [
        {
          text: "観測所",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
          class: "data-table-header"
        },
        {
          text: "現在\n水位",
          align: "right",
          sortable: false,
          value: "level",
          width: "40px",
          class: "data-table-header",
          cellClass: "data-table-cell"
        },
        {
          text: "観測\n開始",
          align: "right",
          sortable: false,
          value: "startStage",
          width: "40px",
          class: "data-table-header start-color",
          cellClass: "data-table-cell"
        },
        {
          text: "水防\n待機",
          align: "right",
          sortable: false,
          value: "standbyLevel",
          width: "40px",
          class: "data-table-header standby-color",
          cellClass: "data-table-cell"
        },
        {
          text: "氾濫\n注意",
          align: "right",
          sortable: false,
          value: "warningLevel",
          width: "40px",
          class: "data-table-header warning-color",
          cellClass: "data-table-cell"
        },
        {
          text: "避難\n判断",
          align: "right",
          sortable: false,
          value: "evacuationLevel",
          width: "40px",
          class: "data-table-header evacuation-color",
          cellClass: "data-table-cell"
        },
        {
          text: "氾濫\n危険",
          align: "right",
          sortable: false,
          value: "dangerousLevel",
          width: "40px",
          class: "data-table-header dangerous-color",
          cellClass: "data-table-cell"
        },
        {
          text: "氾濫\n発生",
          align: "right",
          sortable: false,
          value: "outbreakLevel",
          width: "40px",
          class: "data-table-header outbreak-color",
          cellClass: "data-table-cell"
        }
      ],
      rainHeaders: [
        {
          text: "観測所",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
          class: "data-table-header"
        },
        {
          text: "10分",
          align: "right",
          sortable: false,
          value: "min10",
          width: "40px",
          class: "data-table-header",
          cellClass: "data-table-cell"
        },
        {
          text: "時間",
          align: "right",
          sortable: false,
          value: "min60",
          width: "40px",
          class: "data-table-header",
          cellClass: "data-table-cell"
        },
        {
          text: "累計",
          align: "right",
          sortable: false,
          value: "total",
          width: "40px",
          class: "data-table-header",
          cellClass: "data-table-cell"
        }
      ]
    };
  },
  computed: {
    isShowObservatoryMonitor() {
      return this.$store.getters.isShowObservatoryMonitor;
    },
    offices() {
      return this.$store.getters.offices;
    },
    cities() {
      return this.$store.getters.cities;
    }
  },
  created() {
    if (isPrivate === true) {
      this.modeItems.push({ id: "point", text: "登録地点" });
      this.mode = "point";
    }
    const item = localStorage.getItem(this.storageKey);
    if (item !== null) {
      const info = JSON.parse(item);
      if (info.mode !== undefined) {
        this.mode = info.mode;
      }
      if (info.cityId !== undefined) {
        this.cityId = info.cityId;
      }
      if (info.officeId !== undefined) {
        this.officeId = info.officeId;
      }
    }
  },
  mounted() {
    this.changeMode();
  },
  methods: {
    close() {
      this.$store.commit("SET_IS_SHOW_OBSERVATORY_MONITOR", false);
    },
    getLevelColor(status) {
      switch (status) {
        case "start":
          return STAGES.START.color;
        case "standby":
          return STAGES.STANDBY.color;
        case "warning":
          return STAGES.WARNING.color;
        case "evacuation":
          return STAGES.EVACUATION.color;
        case "dangerous":
          return STAGES.DANGEROUS.color;
        case "outbreak":
          return STAGES.OUTBREAK.color;
        default:
          return "transparent";
      }
    },
    getStagePriority(status) {
      switch (status) {
        case "start":
          return 4;
        case "standby":
          return 5;
        case "warning":
          return 6;
        case "evacuation":
          return 7;
        case "dangerous":
          return 8;
        case "outbreak":
          return 9;
        default:
          return 0;
      }
    },
    getRainColor(val) {
      if (val === undefined || val === null) {
        return "#aaaaaa";
      }
      val = parseFloat(val);
      if (val >= 80) {
        return "#800080";
      } else if (val >= 50) {
        return "#ff0000";
      } else if (val >= 30) {
        return "#d88100";
      } else if (val >= 20) {
        return "#1e42af";
      } else if (val >= 1) {
        return "#2a7aaa";
      } else {
        return "transparent";
      }
    },
    async changeMode() {
      console.log("changeMode");
      if (this.mode == "city") {
        await this.$store.dispatch("FETCH_CITIES");
        if (this.cityId === 0 && this.cities.length > 0) {
          this.cityId = this.cities[0]["_id"];
        }
      } else if (this.mode == "office") {
        await this.$store.dispatch("FETCH_OFFICES");
        if (this.officeId === 0 && this.offices.length > 0) {
          this.officeId = this.offices[0]["_id"];
        }
      }
      this.fetch();
    },
    save() {
      const info = {
        mode: this.mode,
        cityId: this.cityId,
        officeId: this.officeId
      };
      localStorage.setItem(this.storageKey, JSON.stringify(info));
    },
    convertHankaku(val) {
      const s = val.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
      return s.replace("．", ".");
    },
    isDeficit(val) {
      return val === undefined || val === -999;
    },
    async fetch() {
      console.log("fetch");
      this.save();

      let response = null;
      if (this.mode === "point") {
        response = await axiosHelper.get("/observatoryLink/get", {});
      } else {
        response = await axiosHelper.get(`/${this.mode}/observatories`, {
          id: this.mode === "city" ? this.cityId : this.officeId
        });
      }
      this.rains = [];
      this.stages = [];
      if (response === null) {
        return;
      }

      const rainList = response.rainList;
      const waterList = response.waterList;

      // stages
      this.stages = this.$store.getters.observatories
        .filter(obs => obs.type === 4 && waterList.includes(obs._id))
        .map(obs => {
          const s = JSON.parse(JSON.stringify(obs));
          s.priority = this.getStagePriority(s.status);
          if (this.isDeficit(s.level) === true) {
            s.priority = -1;
          }
          // リスク率
          const divisor =
            s.dangerousLevel !== undefined
              ? s.dangerousLevel
              : s.outbreakLevel !== undefined
              ? s.outbreakLevel
              : null;
          s.riskRate = 0;
          if (divisor !== null && this.isDeficit(s.level) === false) {
            s.riskRate = s.level / divisor;
          }
          s.startStage =
            s.startStage === undefined ? "-" : s.startStage.toFixed(2);
          s.standbyLevel =
            s.standbyLevel === undefined ? "-" : s.standbyLevel.toFixed(2);
          s.warningLevel =
            s.warningLevel === undefined ? "-" : s.warningLevel.toFixed(2);
          s.evacuationLevel =
            s.evacuationLevel === undefined
              ? "-"
              : s.evacuationLevel.toFixed(2);
          s.dangerousLevel =
            s.dangerousLevel === undefined ? "-" : s.dangerousLevel.toFixed(2);
          s.outbreakLevel =
            s.outbreakLevel === undefined ? "-" : s.outbreakLevel.toFixed(2);
          s.level = this.isDeficit(s.level) ? "-" : s.level.toFixed(2);
          s.name = this.convertHankaku(s.name);
          s.color = this.getLevelColor(s.status);
          return s;
        });

      // rains
      this.rains = this.$store.getters.observatories
        .filter(obs => obs.type === 1 && rainList.includes(obs._id))
        .map(obs => {
          const s = JSON.parse(JSON.stringify(obs));
          s.priority1 = -1;
          s.priority2 = -1;
          s.priority3 = -1;
          s.min10 = "-";
          s.min60 = "-";
          s.total = "-";
          s.min10Color = "transparent";
          s.min60Color = "transparent";
          s.totalColor = "transparent";
          if (s.status !== undefined) {
            if (this.isDeficit(s.status.min10) === false) {
              s.priority1 = s.status.min10;
              s.min10 = s.status.min10.toFixed(1);
            }
            if (this.isDeficit(s.status.min60) === false) {
              s.priority2 = s.status.min60;
              s.min60 = s.status.min60.toFixed(1);
            }
            if (this.isDeficit(s.status.total) === false) {
              s.priority3 = s.status.total;
              s.total = s.status.total.toFixed(1);
            }
            s.min10Color = this.getRainColor(s.status.min10);
            s.min60Color = this.getRainColor(s.status.min60);
            s.totalColor = this.getRainColor(s.status.total);
          }
          return s;
        });
    },
    moveObservatoryDetail(obs) {
      console.log(obs);
    }
  }
};
</script>

<style lang="scss">
.observatory-monitor-header {
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 30px;
  }
}

.observatory-monitor-contents {
  height: 75vh;
  overflow-y: scroll;
  .theme--light.v-data-table tbody td:nth-child(1),
  .theme--light.v-data-table thead th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 9999;
    background: white;
  }
  .data-table-header {
    padding: 0 3px 0 3px !important;
    min-width: 60px;
    white-space: pre-wrap;
    font-weight: normal;
    border-bottom: 1px double black !important;
    &.start-color {
      color: #22b022 !important;
    }
    &.standby-color {
      color: #008000 !important;
    }
    &.warning-color {
      color: #f2e700 !important;
    }
    &.evacuation-color {
      color: #ff2800 !important;
    }
    &.dangerous-color {
      color: #ad02ad !important;
    }
    &.outbreak-color {
      color: #000000 !important;
    }
  }
  th,
  td {
    height: 24px !important;
    font-size: 12px !important;
    padding: 0 5px 0 3px !important;
  }
  .chip-val {
    height: 18px !important;
    font-size: 12px !important;
  }
}
</style>
