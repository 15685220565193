<template>
  <div></div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import * as axiosHelper from "../../../utils/axiosHelper";
import L from "leaflet";
import geojsonvt from "geojson-vt";

export default {
  name: "KouzuiLayer",
  components: {},
  data() {
    return {
      gridLayer: L.gridLayer()
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    kouzuiInfo() {
      return this.$store.getters.kouzuiInfo;
    },
    selectedDate() {
      return this.$store.getters.selectedDate;
    },
    selectedUrl() {
      if (!this.selectedDate) {
        return null;
      }
      if (!this.kouzuiInfo || this.kouzuiInfo.length === 0) {
        return null;
      }
      const finded = this.kouzuiInfo.find(info =>
        this.selectedDate.isSame(moment.utc(info.date, "YYYY/MM/DD HH:mm"))
      );
      if (finded === undefined || finded.url === undefined) {
        return null;
      }
      return finded.url;
    },
    baseDate() {
      return this.$store.getters.baseDate;
    }
  },
  watch: {
    async selectedUrl(newVal, oldVal) {
      if (newVal === oldVal || newVal === null) {
        return;
      }
      this.loadJson(this.selectedUrl);
    },
    baseDate(newVal, oldVal) {
      if (!newVal.isSame(oldVal)) {
        this.loadData();
      }
    }
  },
  created() {
    this.map.addLayer(this.gridLayer);
    this.loadData();
    this.$store.commit("SET_USAGE_GUIDE", [
      {
        title: "洪水警報の危険度分布",
        colors: [
          { color: "#10000e", value: "災害切迫【５】" },
          { color: "#a5009c", value: "危険　　【４】" },
          { color: "#ff251b", value: "警戒　　【３】" },
          { color: "#f1e539", value: "注意　　【２】" },
          { color: "#00fffe", value: "今後の情報等に留意" }
        ]
      },
      {
        title: "指定河川洪水予報",
        colors: [
          { color: "#10000e", value: "氾濫発生情報【５】" },
          { color: "#a5009c", value: "氾濫危険情報【４】" },
          { color: "#ff251b", value: "氾濫警戒情報【３】" },
          { color: "#f1e539", value: "氾濫注意情報【２】" },
          { color: "#00fffe", value: "発表なし" }
        ]
      }
    ]);
  },
  beforeDestroy() {
    this.gridLayer.remove();
  },
  methods: {
    async loadData() {
      this.$store.commit("SET_KOUZUI_INFO", []);
      const baseDate = this.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");
      const params = {
        date: baseDate
          .clone()
          .utc()
          .format("YYYY/MM/DD HH:mm")
      };
      const response = await axiosHelper.get("/river-risk", params);
      if (response === null) {
        return;
      }
      this.$store.commit("SET_KOUZUI_INFO", response.riverRisks);
      this.$store.commit(
        "SET_SELECTED_DATE",
        moment.utc(response.baseDate, "YYYY/MM/DD HH:mm")
      );
    },
    async loadJson(url) {
      this.gridLayer.remove();
      const res = await axios
        .create({
          responseType: "json"
        })
        .get(url);
      if (res === null) {
        return;
      }
      const tileOptions = {
        maxZoom: 17,
        tolerance: 5,
        extent: 4096,
        buffer: 64,
        debug: 0,
        indexMaxZoom: 0,
        indexMaxPoints: 100000
      };
      const tileIndex = geojsonvt(res.data, tileOptions);
      this.gridLayer.createTile = function(coords) {
        var pad = 0;
        var canvas = document.createElement("canvas");
        canvas.width = 256;
        canvas.height = 256;
        var ctx = canvas.getContext("2d");
        var tile = tileIndex.getTile(coords.z, coords.x, coords.y);
        if (!tile) {
          return canvas;
        }
        var features = tile.features;
        for (var i = 0; i < features.length; i++) {
          var feature = features[i];
          const getLineColor = function(tags) {
            if (tags.FLOODRISK) {
              switch (parseInt(tags.FLOODRISK)) {
                case 1:
                  return "rgba(242,231,0)";
                case 2:
                  return "rgba(255,40,0)";
                case 3:
                  return "rgba(170,0,170)";
                case 4:
                  return "rgba(12,0,12)";
              }
            } else if (tags.FLOODFCST) {
              switch (parseInt(tags.FLOODFCST)) {
                case 2:
                  return "rgba(242,231,0)";
                case 3:
                  return "rgba(255,40,0)";
                case 4:
                  return "rgba(170,0,170)";
                case 5:
                  return "rgba(12,0,12)";
              }
            }
            return null;
          };
          const color = getLineColor(feature.tags);
          ctx.strokeStyle = color ? color : "red";
          ctx.fillStyle = color ? color : "red";
          ctx.lineWidth = feature.tags.FLOODFCST ? 10 : 3;
          ctx.beginPath();

          for (var j = 0; j < feature.geometry.length; j++) {
            var geom = feature.geometry[j];

            for (var k = 0; k < geom.length; k++) {
              var p = geom[k];
              var extent = 4096;

              var x = (p[0] / extent) * 256;
              var y = (p[1] / extent) * 256;
              if (k) ctx.lineTo(x + pad, y + pad);
              else ctx.moveTo(x + pad, y + pad);
            }
          }
          ctx.stroke();
        }

        return canvas;
      };
      this.map.addLayer(this.gridLayer);
    }
  }
};
</script>

<style scoped></style>
