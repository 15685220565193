<template>
  <l-tile-layer
    v-if="selectedBaseTile"
    :url="selectedBaseTile.url"
    :options="options"
    :attribution="attribution"
  />
</template>

<script>
import { LTileLayer } from "vue2-leaflet";
import { BaseTile } from "../../../enums/BaseTile";

export default {
  name: "BaseTileLayer",
  components: {
    LTileLayer
  },
  data() {
    return {
      maxNativeZoom: 17,
      BaseTile,
      options: {
        maxNativeZoom: this.getMaxNativeZoom()
      },
      attribution:
        "<a href='https://maps.gsi.go.jp/development/ichiran.html'>地理院タイル</a>"
    };
  },
  computed: {
    selectedBaseTile() {
      return this.$store.getters.selectedBaseTile;
    }
  },
  methods: {
    getMaxNativeZoom() {
      let maxNativeZoom;

      switch (this.selectedBaseTile) {
        case BaseTile.WHITE:
          maxNativeZoom = 14;
          break;
        case BaseTile.GRAY:
          maxNativeZoom = 15;
          break;
        default:
          maxNativeZoom = 17;
          break;
      }

      return maxNativeZoom;
    }
  }
};
</script>
