import { staticConfig as config } from "../config";

export const BaseTile = {
  STANDARD: {
    index: 0,
    key: "standard",
    string: "標準地図",
    url: `${config.tileServer.url}/xyz/std/{z}/{x}/{y}.png`
  },
  PHOTO: {
    index: 1,
    key: "photo",
    string: "写真地図",
    url: `${config.tileServer.url}/xyz/seamlessphoto/{z}/{x}/{y}.jpg`
  },
  GRAY: {
    index: 2,
    key: "gray",
    string: "淡色地図",
    url: `${config.tileServer.url}/xyz/pale/{z}/{x}/{y}.png`
  },
  WHITE: {
    index: 3,
    key: "white",
    string: "白地図",
    url: `${config.tileServer.url}/xyz/blank/{z}/{x}/{y}.png`
  }
};
