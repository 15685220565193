import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { BaseTile } from "../enums/BaseTile";
import { Risk } from "../enums/Risk";
import * as axiosHelper from "../utils/axiosHelper";
import moment from "moment";
import { OBSERVATORY } from "../enums/Type";
import axios from "axios";
import { isPrivate } from "../utils/common";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";

export default new Vuex.Store({
  state: {
    selectedHazardMaps: [],
    baseDate: moment.utc(),
    selectedRisk: Risk.OBSERVATORY,
    selectedBaseTile: BaseTile.GRAY,
    selectedObservatory: {},
    selectedObservatories: [],
    selectedDate: moment.utc(),
    riskBaseDate: moment.utc(),
    leafletMap: null,
    isShowMapDetail: false,
    mapDetail: {
      title: "",
      contents: ""
    },
    observatories: [],
    isShowUsageGuide: false,
    imageSizeInfo: {},
    rainImageCoordinate: {},
    rainImageInfo: [],
    riskSubControl: {
      observatory: [
        OBSERVATORY.STAGE.code,
        OBSERVATORY.RAIN.code,
        OBSERVATORY.DAM.code,
        OBSERVATORY.CAMERA.code,
        OBSERVATORY.LIVECAMERA.code
      ],
      rain: 0.7,
      dosya: 0.7,
      hazard: isPrivate ? 0.6 : 0.3
    },
    riverFloodingInfo: {},
    usageGuide: {
      title: null,
      colors: []
    },
    hazardMapUsageGuide: {
      url: []
    },
    selectedBranchOffice: {},
    riverFloodingSample: false,
    isShowHazardMapControl: false,
    isShowUsageGuidePanel: false,
    isShowObservatorySubControl: false,
    isShowObservatoryRadar: false,
    isLoading: false,
    riskline: [],
    dosyaInfo: {},
    kouzuiInfo: [],
    bulkHazard: false,
    visibleShelter: false,
    visibleShelterEx: false,
    isShowObservatoryMonitor: false,
    rainInterval: "min10",
    isMytimeline: false,
    offices: [],
    cities: []
  },
  getters: {
    selectedHazardMaps: state => state.selectedHazardMaps,
    baseDate: state => state.baseDate,
    selectedRisk: state => state.selectedRisk,
    selectedBaseTile: state => state.selectedBaseTile,
    selectedObservatory: state => state.selectedObservatory,
    selectedObservatories: state => state.selectedObservatories,
    selectedDate: state => state.selectedDate,
    riskBaseDate: state => state.riskBaseDate,
    imageSizeInfo: state => state.imageSizeInfo,
    rainImageInfo: state => state.rainImageInfo,
    rainImageCoordinate: state => state.rainImageCoordinate,
    leafletMap: state => state.leafletMap,
    isShowMapDetail: state => state.isShowMapDetail,
    mapDetail: state => state.mapDetail,
    observatories: state => state.observatories,
    riskSubControl: state => state.riskSubControl,
    isShowUsageGuide: state => state.isShowUsageGuide,
    riverFloodingInfo: state => state.riverFloodingInfo,
    usageGuide: state => state.usageGuide,
    hazardMapUsageGuide: state => state.hazardMapUsageGuide,
    selectedBranchOffice: state => state.selectedBranchOffice,
    riverFloodingSample: state => state.riverFloodingSample,
    isShowHazardMapControl: state => state.isShowHazardMapControl,
    isShowUsageGuidePanel: state => state.isShowUsageGuidePanel,
    isShowObservatorySubControl: state => state.isShowObservatorySubControl,
    isShowObservatoryRadar: state => state.isShowObservatoryRadar,
    isLoading: state => state.isLoading,
    riskline: state => state.riskline,
    dosyaInfo: state => state.dosyaInfo,
    kouzuiInfo: state => state.kouzuiInfo,
    bulkHazard: state => state.bulkHazard,
    visibleShelter: state => state.visibleShelter,
    visibleShelterEx: state => state.visibleShelterEx,
    isShowObservatoryMonitor: state => state.isShowObservatoryMonitor,
    rainInterval: state => state.rainInterval,
    isMytimeline: state => state.isMytimeline,
    offices: state => state.offices,
    cities: state => state.cities
  },
  mutations: {
    SET_SELECTED_HAZARD_MAPS: function(state, payload) {
      state.selectedHazardMaps = payload;
    },
    SET_BASE_DATE: function(state, payload) {
      state.baseDate = payload;
    },
    SET_SELECTED_RISK: function(state, payload) {
      state.selectedRisk = payload;
    },
    SET_SELECTED_BASE_TILE: function(state, payload) {
      state.selectedBaseTile = payload;
    },
    SET_LEAFLET_MAP: function(state, payload) {
      state.leafletMap = payload;
    },
    SET_OBSERVATORIES: function(state, payload) {
      state.observatories = payload;
    },
    SET_SELECTED_OBSERVATORY: function(state, payload) {
      state.selectedObservatory = payload;
    },
    SET_SELECTED_OBSERVATORIES: function(state, payload) {
      state.selectedObservatories = payload;
    },
    SET_SELECTED_DATE: function(state, payload) {
      state.selectedDate = payload;
    },
    SET_RAIN_IMAGE_COORDINATE: function(state, payload) {
      state.rainImageCoordinate = payload;
    },
    SET_RAIN_IMAGE_INFO: function(state, payload) {
      state.rainImageInfo = payload;
    },
    SET_IMAGE_SIZE_INFO: function(state, payload) {
      state.imageSizeInfo = payload;
    },
    SET_RISK_BASE_DATE: function(state, payload) {
      state.riskBaseDate = payload;
    },
    SET_MAP_DETAIL: function(state, payload) {
      state.isShowMapDetail = payload.isShowing;
      state.mapDetail.title = payload.title;
      state.mapDetail.contents = payload.contents;
    },
    SET_RISK_SUB_CONTROL(state, payload) {
      const cloneRiskSubControl = Object.assign({}, state.riskSubControl);
      if (payload.observatory !== undefined) {
        cloneRiskSubControl.observatory = payload.observatory;
      }
      if (payload.waterKinds !== undefined) {
        cloneRiskSubControl.waterKinds = payload.waterKinds;
      }
      if (payload.rain !== undefined) {
        cloneRiskSubControl.rain = payload.rain;
      }
      if (payload.dosya !== undefined) {
        cloneRiskSubControl.dosya = payload.dosya;
      }
      if (payload.hazard !== undefined) {
        cloneRiskSubControl.hazard = payload.hazard;
      }
      state.riskSubControl = cloneRiskSubControl;
    },
    ["SET_IS_SHOW_USAGE_GUIDE"](state, payload) {
      state.isShowUsageGuide = payload;
    },
    ["SET_SELECTED_AROUND_OBSERVATORIES"](state, payload) {
      state.selectedAroundObservatories = payload.observatories;
    },
    ["SET_RIVER_FLOODING_INFO"](state, payload) {
      state.riverFloodingInfo = {
        type: payload.type,
        bounds: payload.bounds,
        imageInfos: payload.imageInfos
      };
    },
    SET_USAGE_GUIDE(state, payload) {
      state.usageGuide = payload;
    },
    SET_HAZARD_MAP_USAGE_GUIDE: function(state, payload) {
      state.hazardMapUsageGuide = payload;
    },
    SET_RIVER_FLOODING_SAMPLE: function(state, payload) {
      state.riverFloodingSample = payload;
    },
    SET_IS_SHOW_HAZARD_MAP_CONTROL: function(state, payload) {
      state.isShowHazardMapControl = payload;
    },
    SET_IS_SHOW_USAGE_GUIDE_PANEL: function(state, payload) {
      state.isShowUsageGuidePanel = payload;
    },
    SET_IS_SHOW_OBSERVATORY_SUB_CONTROL: function(state, payload) {
      state.isShowObservatorySubControl = payload;
    },
    SET_IS_SHOW_OBSERVATORY_RADAR: function(state, payload) {
      state.isShowObservatoryRadar = payload;
    },
    SET_IS_LOADING: function(state, payload) {
      state.isLoading = payload;
    },
    SET_RISKLINE: function(state, payload) {
      state.riskline = payload;
    },
    SET_DOSYA_INFO: function(state, payload) {
      state.dosyaInfo = payload;
    },
    SET_KOUZUI_INFO: function(state, payload) {
      state.kouzuiInfo = payload;
    },
    SET_BULK_HAZARD: function(state, payload) {
      state.bulkHazard = payload;
    },
    SET_VISIBLE_SHELTER: function(state, payload) {
      state.visibleShelter = payload;
    },
    SET_VISIBLE_SHELTER_EX: function(state, payload) {
      state.visibleShelterEx = payload;
    },
    SET_IS_SHOW_OBSERVATORY_MONITOR: function(state, payload) {
      state.isShowObservatoryMonitor = payload;
    },
    SET_RAIN_INTERVAL: function(state, payload) {
      state.rainInterval = payload;
    },
    SET_IS_MYTIMELINE: function(state, payload) {
      state.isMytimeline = payload;
    },
    SET_OFFICES: function(state, payload) {
      state.offices = payload;
    },
    SET_CITIES: function(state, payload) {
      state.cities = payload;
    }
  },
  actions: {
    FETCH_OBSERVATORIES: async function(context) {
      context.commit("SET_IS_LOADING", true);
      context.commit("SET_OBSERVATORIES", []);
      const observatories = await axiosHelper.get("/observatories", {});
      if (observatories === null) {
        context.commit("SET_IS_LOADING", false);
        throw new Error("fetch failed : observatories");
      }
      observatories.forEach(obs => {
        if (
          obs.type === OBSERVATORY.CAMERA.code ||
          obs.type === OBSERVATORY.LIVECAMERA.code ||
          obs.type === OBSERVATORY.DAM.code
        ) {
          obs.status = "normal";
        } else {
          obs.status = "deficit";
        }
      });

      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");

      const params = {
        date: baseDate.format(DATE_FORMAT)
      };
      const response = await axiosHelper.get("/observatories/status", params);
      if (observatories !== null) {
        const rains = response.rainList;
        const waters = response.waterList;
        observatories.forEach(obs => {
          if (obs.type === OBSERVATORY.RAIN.code) {
            const target = rains.find(r => r._id === obs._id);
            if (target !== undefined && target.rain !== undefined) {
              obs.status = {
                min10: target.rain,
                min60: target.rain60,
                total: target.rainTotal
              };
            }
          } else if (obs.type === OBSERVATORY.STAGE.code) {
            const target = waters.find(
              r => r._id === obs._id && r.level !== undefined && r.level > -999
            );
            if (target !== undefined && target.level !== undefined) {
              obs.level = target.level;
              if (
                obs.outbreakLevel !== undefined &&
                target.level >= obs.outbreakLevel
              ) {
                obs.status = "outbreak";
              } else if (
                obs.dangerousLevel !== undefined &&
                target.level >= obs.dangerousLevel
              ) {
                obs.status = "dangerous";
              } else if (
                obs.evacuationLevel !== undefined &&
                target.level >= obs.evacuationLevel
              ) {
                obs.status = "evacuation";
              } else if (
                obs.warningLevel !== undefined &&
                target.level >= obs.warningLevel
              ) {
                obs.status = "warning";
              } else if (
                obs.standbyLevel !== undefined &&
                target.level >= obs.standbyLevel
              ) {
                obs.status = "standby";
              } else if (
                obs.startStage !== undefined &&
                target.level >= obs.startStage
              ) {
                obs.status = "start";
              } else {
                obs.status = "normal";
              }
            }
          }
        });
      }

      context.commit("SET_OBSERVATORIES", observatories);
      context.commit("SET_RISK_BASE_DATE", baseDate);
      context.commit("SET_IS_LOADING", false);
    },
    FETCH_SELECTED_OBSERVATORY_DATA: async function(context, target) {
      const observatory = Object.assign({}, target);
      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 5, "minutes");

      const params = {
        date: baseDate.format(DATE_FORMAT),
        observatory_id: observatory._id,
        type: observatory.type,
        mobile: 1
      };

      let response = null;
      response = await axiosHelper.get("/observatories/data", params);
      if (response === null) {
        throw new Error("fetch failed : observatories/data");
      }
      let selectedInfo = response.observatories.find(
        obs => obs._id === observatory._id
      );
      if (selectedInfo === undefined) {
        selectedInfo = {};
      }
      const selected = Object.assign(observatory, selectedInfo);
      selected.baseTime = response.baseTime;
      context.commit("SET_SELECTED_OBSERVATORY", selected);

      let contents = "";
      let title = "";
      const observatoryName = selected.name === undefined ? "" : selected.name;
      switch (selected.type) {
        case OBSERVATORY.RAIN.code:
          contents = "rainfall-detail";
          title = `${OBSERVATORY.RAIN.name}観測所 - ${observatoryName}`;
          break;
        case OBSERVATORY.STAGE.code:
          contents = "stage-detail";
          title = `${OBSERVATORY.STAGE.name}観測所 - ${observatoryName}`;
          break;
        case OBSERVATORY.DAM.code:
          contents = "dam-detail";
          title = `${OBSERVATORY.DAM.name}観測所 - ${observatoryName}`;
          break;
        case OBSERVATORY.CAMERA.code:
          contents = "camera-detail";
          title = `${OBSERVATORY.CAMERA.name}`;
          break;
        case OBSERVATORY.LIVECAMERA.code:
          contents = "live-camera-detail";
          title = `${OBSERVATORY.LIVECAMERA.name}`;
          break;
      }

      context.commit("SET_MAP_DETAIL", {
        isShowing: true,
        title: title,
        contents: contents
      });
    },
    FETCH_RAIN: async function(context, { level }) {
      context.commit("SET_IS_LOADING", true);
      const baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");

      const params = {
        date: baseDate.format(DATE_FORMAT),
        level: level
      };
      const response = await axiosHelper.get("/rain", params);

      if (response === null) {
        context.commit("SET_IS_LOADING", false);
        throw new Error("fetch failed : rainfall");
      }

      context.commit(
        "SET_SELECTED_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );
      context.commit("SET_RAIN_IMAGE_COORDINATE", response.bounds);
      context.commit(
        "SET_RISK_BASE_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );
      context.commit("SET_IMAGE_SIZE_INFO", response.imageInfo);

      const rains = response.rains;

      const loadImage = src => {
        return new Promise(resolve => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = () => resolve(null);
          image.src = src;
        });
      };

      let rainImageInfos = rains.map(async info => {
        const image = await loadImage(info.url);
        return {
          date: info.date,
          type: info.type,
          image: image
        };
      });

      let res = await Promise.all(rainImageInfos);
      res = res.filter(row => row.image !== null);
      context.commit("SET_RAIN_IMAGE_INFO", res);
      context.commit("SET_IS_LOADING", false);
    },

    FETCH_RIVER_FLOODING: async function(context) {
      const baseDate = this.state.baseDate.clone();

      const parmas = {
        date: baseDate.utc().format(DATE_FORMAT),
        type: 3
      };

      const response = await axiosHelper.get("/small-river-flooding", parmas);

      if (response === null) {
        throw new Error("fetch failed : river-floods");
      }

      context.commit(
        "SET_RISK_BASE_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );
      context.commit(
        "SET_SELECTED_DATE",
        moment.utc(response.baseDate, DATE_FORMAT)
      );

      context.commit("SET_RIVER_FLOODING_INFO", {
        type: 3,
        bounds: response.boundsList,
        imageInfos: response.floodings
      });
    },

    FETCH_RISKLINE: async function(context) {
      const baseDate = this.state.baseDate.clone();

      const params = {
        date: baseDate.utc().format(DATE_FORMAT)
      };

      const response = await axiosHelper.get("/risk-line", params);

      if (response === null) {
        throw new Error("fetch failed : riskline");
      }

      const riskline = response.ts.map(o => {
        const features = o.lines.map(o => {
          return {
            type: "Feature",
            properties: {
              _id: o.id,
              level: o.value
            },
            geometry: {
              type: "LineString",
              coordinates: [
                [parseFloat(o.startX), parseFloat(o.startY)],
                [parseFloat(o.endX), parseFloat(o.endY)]
              ]
            }
          };
        });
        return {
          lines: {
            type: "FeatureCollection",
            features: features
          },
          date: o.date
        };
      });

      // const responseBaseDate = moment.utc(response.baseDate, DATE_FORMAT);
      // context.commit("SET_RISK_BASE_DATE", responseBaseDate);
      context.commit("SET_RISKLINE", riskline);
      // context.commit("SET_SELECTED_DATE", responseBaseDate);
    },
    FETCH_DOSYA: async function(context) {
      context.commit("SET_IS_LOADING", true);
      let baseDate = this.state.baseDate.clone();
      baseDate.subtract(baseDate.minute() % 10, "minutes");
      const params = {
        date: baseDate.utc().format(DATE_FORMAT)
      };
      context.commit("SET_DOSYA_INFO", {});
      axios
        .create({
          responseType: "json"
        })
        .get("https://data.riskma.net/bosai/dosya", { params })
        .then(async response => {
          const res = response.data;
          const dosyaInfo = {
            bounds: [],
            imageInfos: [],
            size: {}
          };
          if (!res.imageInfos) {
            context.commit("SET_IS_LOADING", false);
            context.commit("SET_DOSYA_INFO", dosyaInfo);
            return;
          }
          dosyaInfo.bounds = res.bounds;
          dosyaInfo.size = res.size;
          const loadImage = src => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.onload = () => resolve(image);
              image.onerror = e => reject(e);
              image.src = src;
            });
          };
          for (let i = 0; i < res.imageInfos.length; i++) {
            const info = res.imageInfos[i];
            const image = await loadImage(info.url);
            dosyaInfo.imageInfos.push({
              date: info.date,
              image: image
            });
          }
          baseDate = res.baseDate
            ? moment.utc(res.baseDate, DATE_FORMAT)
            : baseDate;
          context.commit("SET_SELECTED_DATE", baseDate);
          context.commit("SET_DOSYA_INFO", dosyaInfo);
          context.commit("SET_IS_LOADING", false);
        })
        .catch(err => {
          context.commit("SET_IS_LOADING", false);
          console.log(err);
        });
    },

    FETCH_OFFICES: async function(context) {
      if (this.state.offices.length > 0) {
        return;
      }
      const response = await axiosHelper.get("/offices", {});
      if (response === null) {
        context.commit("SET_OFFICES", []);
      } else {
        context.commit("SET_OFFICES", response);
      }
    },
    FETCH_CITIES: async function(context) {
      if (this.state.cities.length > 0) {
        return;
      }
      const response = await axiosHelper.get("/cities", {});
      if (response === null) {
        context.commit("SET_CITIES", []);
      } else {
        context.commit("SET_CITIES", response);
      }
    }
  }
});
