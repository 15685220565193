<template>
  <div>
    <h5>予測降雨透明度</h5>
    <v-slider v-model="opacity" hide-details :step="0.01" :max="1" :min="0">
    </v-slider>
  </div>
</template>

<script>
export default {
  name: "RainRiskSubControlLayer",
  data() {
    return {
      opacity: 0.7
    };
  },
  watch: {
    opacity(value) {
      this.$store.commit("SET_RISK_SUB_CONTROL", { rain: value });
    }
  },
  mounted() {
    this.$store.commit("SET_USAGE_GUIDE", {
      title: "レーダ・予測雨量\n(mm/h)",
      colors: [
        { color: "#ba206e", value: "80〜" },
        { color: "#ff3700", value: "50〜80" },
        { color: "#ffa021", value: "30〜50" },
        { color: "#fbf43c", value: "20〜30" },
        { color: "#0041FF", value: "10〜20" },
        { color: "#218CFF", value: "5〜10" },
        { color: "#A0D2FF", value: "1〜5" },
        { color: "#99FFFF", value: "0〜1" }
      ]
    });
  }
};
</script>

<style scoped>
h5 {
  opacity: 0.8;
}
</style>
