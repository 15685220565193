export const Features = {
  shinsuishin_kuni_data: {
    name:
      '<div style="line-height: 15px">洪水浸水想定区域<br>(想定最大規模)</div>',
    index: 1,
    key: "shinsuishin_kuni_data",
    public: true,
    private: true,
    string: "洪水浸水想定区域\n想定浸水深（ｍ）",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/sinsou/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      colors: [
        { color: "rgb(220, 122, 220)", value: "20.0~" },
        { color: "rgb(242, 133, 201)", value: "10.0~20.0" },
        { color: "rgb(255, 145, 145)", value: "5.0~10.0" },
        { color: "rgb(255, 183, 183)", value: "3.0~5.0" },
        { color: "rgb(255, 216, 192)", value: "0.5~3.0" },
        { color: "rgb(247, 245, 169)", value: "~0.5" }
      ]
    }
  },
  shinsuishin_newlegend_data: {
    name: '<div style="line-height: 15px">洪水浸水想定区域<br>(計画規模)</div>',
    index: 2,
    key: "shinsuishin_newlegend_data",
    public: true,
    private: true,
    string: "洪水浸水想定区域\n想定浸水深（ｍ）",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/sinsouL1/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      colors: [
        { color: "rgb(220, 122, 220)", value: "20.0~" },
        { color: "rgb(242, 133, 201)", value: "10.0~20.0" },
        { color: "rgb(255, 145, 145)", value: "5.0~10.0" },
        { color: "rgb(255, 183, 183)", value: "3.0~5.0" },
        { color: "rgb(255, 216, 192)", value: "0.5~3.0" },
        { color: "rgb(247, 245, 169)", value: "~0.5" }
      ]
    }
  },
  shinsui_keizoku: {
    name: '<div style="line-height: 30px">浸水継続時間</div>',
    index: 3,
    key: "shinsui_keizoku",
    public: true,
    private: true,
    string: "浸水継続時間\n(想定最大規模)",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/keizoku_L2/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      colors: [
        { color: "rgb(160, 210, 255)", value: "12時間未満" },
        { color: "rgb(0, 65, 255)", value: "12時間〜1日未満" },
        { color: "rgb(250, 245, 0)", value: "1日〜3日未満" },
        { color: "rgb(255, 153, 0)", value: "3日〜1週間未満" },
        { color: "rgb(255, 40, 0)", value: "1週間〜2週間未満" },
        { color: "rgb(180, 0, 104)", value: "2週間〜4週間未満" },
        { color: "rgb(96, 0, 96)", value: "4週間以上〜" }
      ]
    }
  },
  dosyasaigai_keikaikuiki: {
    name: '<div style="line-height: 30px">土砂災害警戒区域</div>',
    icon: "mdi-terrain",
    index: 4,
    key: "dosyasaigai_keikaikuiki",
    public: true,
    private: true,
    string: "土砂災害警戒区域",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/R04ji/{z}/{x}/{y}.png",
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/R04do/{z}/{x}/{y}.png",
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/R04ky/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      colors: [
        { color: "rgb(193, 76, 98)", value: "土石流", title: "特別警戒区域" },
        { color: "rgb(252, 104, 77)", value: "急傾斜地の崩壊" },
        { color: "rgb(203, 76, 147)", value: "地滑り" },
        { color: "rgb(238, 216, 118)", value: "土石流", title: "警戒区域" },
        { color: "rgb(255, 237, 90)", value: "急傾斜地の崩壊" },
        { color: "rgb(255, 183, 84)", value: "地滑り" }
      ]
    }
  },
  kaokutoukai_hanran_kuni_data: {
    name:
      '<div style="line-height: 15px">家屋倒壊等氾濫想定区域<br>(氾濫流)</div>',
    icon: "mdi-run",
    index: 5,
    key: "kaokutoukai_hanran_kuni_data",
    public: true,
    private: true,
    string: "家屋倒壊等氾濫想定\n区域(氾濫流)",
    group: "ハザードマップ",
    urls: [
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_hanran_kuni_data/{z}/{x}/{y}.png",
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_hanran_pref_data/10/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      image: `https://disaportal.gsi.go.jp/hazardmap/copyright/img/kaokutoukai_hanran.png`
    }
  },
  kaokutoukai_kagan_kuni_data: {
    name:
      '<div style="line-height: 15px">家屋倒壊等氾濫想定区域<br>(河岸侵食)</div>',
    icon: "mdi-run",
    index: 6,
    key: "kaokutoukai_kagan_kuni_data",
    public: true,
    private: true,
    string: "家屋倒壊等氾濫想定\n区域(河岸侵食)",
    group: "ハザードマップ",
    urls: [
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_kagan_kuni_data/{z}/{x}/{y}.png",
      "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_kagan_pref_data/10/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      image: `https://disaportal.gsi.go.jp/hazardmap/copyright/img/kaokutoukai_kagan.png`
    }
  },
  taihushusui_sinsui21: {
    name:
      '<div style="line-height: 15px">過去台風出水時浸水実績<br>(H29台風21号浸水実績)</div>',
    icon: "mdi-terrain",
    index: 7,
    key: "taihushusui_sinsui21",
    public: false,
    private: true,
    string: "過去台風出水時浸水実績",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/GNMAFloodRecordH29/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      colors: [{ color: "#77E2FD", value: "浸水区域" }]
    }
  },
  taihushusui_sinsui19: {
    name:
      '<div style="line-height: 15px">過去台風出水時浸水実績<br>(R01台風19号浸水実績)</div>',
    icon: "mdi-terrain",
    index: 8,
    key: "taihushusui_sinsui19",
    public: false,
    private: true,
    string: "過去台風出水時浸水実績",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/GNMAFloodRecordR01/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      colors: [{ color: "#77E2FD", value: "浸水区域" }]
    }
  },
  under_path: {
    name: '<div style="line-height: 30px">道路冠水注意箇所</div>',
    icon: "mdi-terrain",
    index: 9,
    key: "under_path",
    public: true,
    private: true,
    string: "道路冠水注意箇所",
    group: "ハザードマップ",
    urls: [
      "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/under-path/{z}/{x}/{y}.png"
    ],
    usageGuide: {
      image:
        "https://d1gpfmzkl8v4u5.cloudfront.net/static/tile/under-path/legend.png"
    }
  }
};
