var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedDateRiskData)?_c('div',{staticStyle:{"z-index":"20000 !important"}},[(_vm.rectangle.bounds != null)?_c('l-rectangle',{attrs:{"bounds":_vm.rectangle.bounds,"l-style":_vm.rectangle.style}}):_vm._e(),_vm._l((_vm.slicedImages),function(slicedImage,index){return [(slicedImage.isInDeviceViewPort)?_c('l-marker',{key:index,attrs:{"lat-lng":[slicedImage.markerLat, slicedImage.markerLng]}},[_c('l-icon',{attrs:{"icon-anchor":[0, 0]}},[_c('div',{style:({
            width: slicedImage.divWidth + 'px',
            height: slicedImage.divHeight + 'px',
            overflow: 'hidden',
            'pointer-events': 'none'
          })},[_c('img',{staticClass:"riskImage",style:({
              transform: `translate(0,${slicedImage.translateY}px)`,
              width: slicedImage.imgWidth + 'px',
              height: slicedImage.imgHeight + 'px',
              'pointer-events': 'none',
              opacity: _vm.opacity
            }),attrs:{"alt":"rainfallImage","src":_vm.selectedDateRiskData.image.src}})])])],1):_vm._e()]}),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('iframe',{attrs:{"width":"990","height":"660","src":_vm.snakeLineUrl}})],1)],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }