<template>
  <div class="hazard-map-control-container" v-show="isShowHazardMapControl">
    <v-card class="mb-1" style="pointer-events: painted;" width="140">
      <div class="pl-2 pt-1" style="font-size: 10px">リスク透明度</div>
      <v-slider v-model="opacity" hide-details :step="0.01" :max="1" :min="0">
      </v-slider>
    </v-card>
    <div v-for="(info, index) in usageGuides" :key="index">
      <v-card width="140" class="pa-2 mb-1">
        <div class="guide-title">{{ info.string }}</div>
        <template v-if="info.usageGuide.colors">
          <div v-for="(o, index2) in info.usageGuide.colors" :key="index2">
            <div class="mt-2 guide-title" v-if="o.title">
              {{ o.title }}
            </div>
            <div style="height: 12px;">
              <div
                class="guide-colors"
                :style="`background-color: ${o.color};`"
              />
              <span class="pl-2 guide-text">{{ o.value }}</span>
            </div>
          </div>
        </template>
        <template v-if="info.usageGuide.image">
          <v-img
            :src="info.usageGuide.image"
            max-height="30"
            max-width="40"
            contain
            class="pt-2"
          />
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "HazardMapControl",
  data() {
    return {
      isOpen: true,
      opacity: 0.3
    };
  },
  watch: {
    opacity(value) {
      this.$store.commit("SET_RISK_SUB_CONTROL", { hazard: value });
    }
  },
  created() {
    this.opacity = this.$store.getters.riskSubControl.hazard;
  },
  computed: {
    isShowHazardMapControl() {
      return this.$store.getters.isShowHazardMapControl;
    },
    usageGuides() {
      const selected = this.$store.getters.selectedHazardMaps;
      const res = [];
      selected.forEach(f => {
        if (f.usageGuide !== undefined) {
          const finded = res.find(
            r => JSON.stringify(r.usageGuide) === JSON.stringify(f.usageGuide)
          );
          if (finded === undefined) {
            res.push({
              string: f.string,
              usageGuide: { ...f.usageGuide }
            });
          }
        }
      });
      return res;
    }
  }
};
</script>

<style lang="scss">
.hazard-map-control-container {
  height: auto;
  .guide-title {
    line-height: 11px;
    white-space: pre-wrap;
    font-size: 11px;
    opacity: 0.8;
  }
  .guide-colors {
    width: 20px;
    height: 8px;
    opacity: 0.8;
    display: inline-block;
  }
  .guide-text {
    font-size: 11px;
    opacity: 0.8;
  }
}
</style>
