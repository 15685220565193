<template>
  <div class="rain-level-detail pa-3">
    <div class="caption text--secondary">
      {{ selectedObservatory.address }}
    </div>
    <div class="caption text--secondary">
      {{ selectedObservatory.lat }}, {{ selectedObservatory.lng }}
    </div>
    <div v-if="!isDeficit" class="my-2">
      <div class="d-flex align-center">
        <v-card width="120" class="caption text--secondary mr-3" flat>
          予測期間
        </v-card>
        <v-card width="120" class="caption text--secondary" flat>
          時間表示
        </v-card>
      </div>
      <v-card class="d-flex align-center" flat tile>
        <v-btn-toggle v-model="displayHours" mandatory dense class="mr-3">
          <v-btn small :value="6" width="60">
            6時間
          </v-btn>
          <v-btn small :value="36" width="60">
            36時間
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle v-model="displayInterval" mandatory dense>
          <v-btn small :value="10" width="60">
            10分毎
          </v-btn>
          <v-btn small :value="60" width="60">
            時間毎
          </v-btn>
        </v-btn-toggle>
      </v-card>
    </div>
    <div class="d-flex mb-1">
      <div class="caption">{{ `(mm/${displayInterval}分)` }}</div>
      <div class="caption ml-auto">(mm)</div>
    </div>
    <RainChart
      v-if="!isDeficit"
      :height="250"
      :currentJst="currentJst"
      :timeSeries="timeSeries"
      :displayInterval="displayInterval"
    />
    <div class="my-2">
      <v-chip
        v-for="(t, index) in thresholds"
        :key="index"
        :color="t.color"
        dark
        x-small
        label
        class="mr-1 px-1"
        >{{ t.value }}</v-chip
      >
    </div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">日時</th>
            <th class="text-left">
              {{ displayInterval === 10 ? "10分" : "60分" }}
            </th>
            <th class="text-left">累加</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(ts, i) in timeSeries">
            <tr :key="i" :class="{ forecast: ts.type === 'forecast' }">
              <td>{{ ts.time }}</td>
              <td>
                <v-chip
                  label
                  :color="getColor(ts.value)"
                  :dark="ts.value === null || ts.value >= 1"
                  class="chip-val"
                >
                  {{ getDisplayValue(ts.value) }}
                </v-chip>
              </td>
              <td>
                <v-chip
                  label
                  :color="getColor(ts.total)"
                  :dark="ts.total === null || ts.total >= 1"
                  class="chip-val"
                >
                  {{ getDisplayValue(ts.total) }}
                </v-chip>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div class="mt-5 caption" v-if="isDeficit">
      この観測所は欠測しています...
    </div>
  </div>
</template>

<script>
import RainChart from "./RainChart";

export default {
  name: "RainDetail",
  components: { RainChart },
  data() {
    return {
      displayHours: 6,
      displayInterval: 10,
      thresholds: [
        { color: "#2a7aaa", value: "1㎜以上" },
        { color: "#1e42af", value: "20㎜以上" },
        { color: "#d88100", value: "30㎜以上" },
        { color: "#ff0000", value: "50㎜以上" },
        { color: "#800080", value: "80㎜以上" }
      ]
    };
  },
  computed: {
    selectedObservatory() {
      return this.$store.getters.selectedObservatory;
    },
    rainVolumes() {
      if (this.selectedObservatory.rainVolumes === undefined) {
        return [];
      }
      return this.selectedObservatory.rainVolumes;
    },
    isDeficit() {
      return this.rainVolumes.length === 0;
    },
    currentJst() {
      const baseDate = this.$store.getters.baseDate.clone();
      baseDate.subtract(baseDate.minute() % this.displayInterval, "minutes");
      return baseDate.local().format("YYYY/MM/DD HH:mm");
    },
    timeSeries() {
      if (this.isDeficit === true) {
        return [];
      }

      const baseDate = this.$store.getters.baseDate.clone();
      baseDate.subtract(baseDate.minute() % this.displayInterval, "minutes");

      const isMin60 = this.displayInterval === 60;

      const realHours = isMin60 ? 23 : 3;
      const times = (realHours + this.displayHours) * (isMin60 ? 1 : 6) + 1;
      const d = baseDate.clone().subtract(realHours, "hours");
      return [...Array(times).keys()].map(() => {
        const jst = d.clone().local();
        const ts = {
          utc: d.format("YYYY/MM/DD HH:mm"),
          jst: jst.format("YYYY/MM/DD HH:mm"),
          time: jst.format("MM/DD HH:mm"),
          type: baseDate.isSameOrAfter(d) ? "real" : "forecast",
          value: null,
          total: null
        };
        const finded = this.rainVolumes.find(r => r.dateTime === ts.utc);
        if (finded !== undefined) {
          const elm = isMin60 ? "min60" : "min10";
          if (finded[elm] !== undefined) {
            ts.value = finded[elm];
          }
          if (finded.total !== undefined) {
            ts.total = finded.total;
          }
          // 現在時刻に予測値が入る場合がある
          if (finded.type !== undefined) {
            ts.type = finded.type === "real" ? "real" : "forecast";
          }
        }
        d.add(isMin60 ? 60 : 10, "minutes");
        return ts;
      });
    }
  },
  methods: {
    getDisplayValue(val) {
      return val === null ? "---" : parseFloat(val).toFixed(2);
    },
    getColor(val) {
      if (val === undefined || val === null) {
        return "#aaaaaa";
      }
      val = parseFloat(val);
      if (val >= 80) {
        return "#800080";
      } else if (val >= 50) {
        return "#ff0000";
      } else if (val >= 30) {
        return "#d88100";
      } else if (val >= 20) {
        return "#1e42af";
      } else if (val >= 1) {
        return "#2a7aaa";
      } else {
        return "transparent";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rain-level-detail {
  thead {
    background-color: #addff3;
  }
  tbody td {
    background: #eee;
  }
  tbody tr:nth-child(odd) td {
    background: #fff;
  }
  .forecast {
    color: blue;
  }
  .chip-val {
    height: 22px;
    min-width: 60px;
    text-align: right;
  }
}
</style>
