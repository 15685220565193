<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "app",
  mounted() {
    console.log(process.env.VUE_APP_DOMAIN);
    console.log(process.env.NODE_ENV);
  }
};
</script>

<style>
@supports (-webkit-touch-callout: none) {
  html {
    height: -webkit-fill-available;
  }
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}
html,
body {
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 0px;
}
img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
}
.leaflet-pane.leaflet-popup-pane {
  z-index: 2000;
}
</style>

<style lang="scss">
.leaflet-bottom.leaflet-right {
  .leaflet-control-attribution.leaflet-control {
    pointer-events: painted !important;
  }
}
</style>
